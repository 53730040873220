import { checkoutResource, type BookingResource, type CheckoutResource } from '@/api/resources';
import { defineStore } from 'pinia';
import { isAxiosError } from 'axios';
import config from '@/config';
import { persist } from '@/stores';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { useLoadingStore } from '@/stores/useLoadingStore';
import { useValidationStore } from '@/stores/useValidationStore';
import { z } from 'zod';
import api from '@/api';
import Cookies from "js-cookie";
import useTransaction from '@/compositions/useTransaction';
import useCookie from '@/compositions/useCookie';

const stateSchema = z.object({
  id: z.string(),
  checkout: checkoutResource,
});

export type State = z.infer<typeof stateSchema>;

export type CheckoutOptions = {
  checkout?: string
  product?: number
  bath_card_id?: number
  voucher_codes?: string[]
  arrival_date?: string
  departure_date?: string
  persons_count?: string
  checkout_type?: string
  ga_origin?: string
}

let stopWatcher: () => void;

/***************************************************************************************
 *                                                                                     *
 * ⛔️ STOP! DON'T JUST ADD ANYTHING, KEEP IN MIND EVERY UPDATE RESULTS IN AN API CALL. *
 *                                                                                     *
 ***************************************************************************************/
export const useFlowStore = defineStore('flow', {
  state: (): State => ({

    /* Same as checkout.checkout_id, but presisted, used to retrieve checkout data from the server */
    id: persist('flow/id', ''),

    /* Checkout does always exist since it will be preloaded in App.vue */
    checkout: {} as CheckoutResource,

  }),

  getters: {
    isHotelFlow(state) {
      return state.checkout?.meta?.product?.type === 'hotel';
    },

    hasReceiptUpsellsAvailable(state) {
      if (state.checkout?.meta?.upsells?.receipt) {
        return !state.checkout.meta.upsells.receipt.products
          .every(product => product.persons
            ?.every(person => person.selected) || !product.is_available);
      }
      return false;
    },

    comparableCheckoutTypes(state) {
      if (state.checkout?.meta?.supported_checkout_types) {
        return state.checkout?.meta?.supported_checkout_types
          .filter(checkoutType => checkoutType.image !== null);
      }
      return [];
    },

    simpleCheckoutTypes(state) {
      if (state.checkout?.meta?.supported_checkout_types) {
        return state.checkout?.meta?.supported_checkout_types
          .filter(checkoutType => checkoutType.image === null);
      }
      return [];
    },

    entreeProducts(state) {
      return Object.entries(state.checkout?.meta?.entree_product_ids ?? {})
        .map(([key, product_id]) => ({ key, product_id }))
        .filter((item): item is { key: string, product_id: number } => item.product_id !== null);
    },
  },

  actions: {

    resetFlow() {
      this.id = '';
    },

    /**
     * Store the checkout cart data on the server.
     */
    async save(noPatching: boolean = false): Promise<void> {
      const loadingStore = useLoadingStore();
      const transaction = useTransaction();

      // In case of resetting the flow, ex. changing the checkout type.
      // the id is reset to and empty string and we don't want to store this. So we resolve directly.
      if (this.id === '') return Promise.resolve();

      const result = checkoutResource.safeParse(this.$state.checkout);
      if (result.success) {
        const loaders = noPatching ? [] : loadingStore.loading('patching');
        const checkoutData = await api.checkout.updateCheckout(this.id, this.$state.checkout);

        if (!noPatching) {
          this.$patch({ checkout: checkoutData });
        }

        transaction.patch(); // Register that there was a patch.
        loadingStore.unloading(...loaders);
      } else {
        console.error('schema validation failed');
      }
    },

    /**
     * Load an existing or new checkout with cart data from the server into the flow state.
     */
    async load(options: CheckoutOptions = {}): Promise<void> {
      const checkoutData = await api.checkout.createCheckout({
        checkout_id: options.checkout ? options.checkout : this.id,
        product_id: options.product,
        voucher_codes: options.voucher_codes,
        bath_card_id: options.bath_card_id,
        persons_count: options.persons_count,
        arrival: options.arrival_date ? { date: options.arrival_date } : undefined,
        departure: options.departure_date ? { date: options.departure_date } : undefined,
        checkout_type: options.checkout_type,
        ga_origin: options.ga_origin,
      });

      this.$patch({ id: checkoutData.data.checkout_id, checkout: checkoutData });
    },

    /**
     * Change the main product of the flow.
     */
    async changeMainProduct(product_id: number) {
      const loadingStore = useLoadingStore();
      const loaders = loadingStore.loading('changing_main_product');
      this.$patch({ checkout: { data: { product_id }}});
      await this.save();
      loadingStore.unloading(...loaders);
    },

    /**
     * Change the checkout date of the reservation
     */
    async setCheckoutDate(arrivalDate: string, departureDate: string | null) {
      const loadingStore = useLoadingStore();
      const loaders = loadingStore.loading('changing_date');
      const data = {
        arrival: { date: arrivalDate },
        departure: { date: departureDate }
      };

      // BUGFIX: For some reason pinia does not patch/remove record keys correctly
      // that's why notices is set here explicit to undefined.
      this.$patch({ checkout: { data, notices: undefined, calendar_notifications: undefined }});
      await this.save();
      loadingStore.unloading(...loaders);
    },

    /**
     * Set checkout time, will be stored on the server but the result of the api
     * is not patched back into the store, to be non-intrusive.
     */
    async setCheckoutTime(type: 'arrival' | 'departure', time: string | null) {
      this.$patch({ checkout: { data: { [type]: { time } }}});
      await this.save();
      // await this.save(true);
    },

    /**
     * Sets the persons count and optimistic removes the person from the store,
     * saves the state and patches the data back.
     */
    async setPersonsCount(persons_count: number) {
      const loadingStore = useLoadingStore();

      // Only show the loader when incrementing number of persons.
      const loaders = persons_count <= this.checkout.data.persons_count ? [] :loadingStore.loading('changing_person_choice');

      this.$patch({
        checkout: {
          data: { persons_count },
          meta: {
            receipt: {
              persons: this.checkout.meta.receipt.persons.slice(0, persons_count),
            }
          }
        }
      });

      await this.save();
      loadingStore.unloading(...loaders);
    },

    /**
     * Update a person product choice.
     */
    async setPersonChoice(personId: number, slot: string, productId: number): Promise<void> {
      const loadingStore = useLoadingStore();
      const transaction = useTransaction();

      const loaders = loadingStore.loading('patching', 'changing_person_choice');

      const checkoutData = await api.checkout.setPersonChoice(this.id, personId, slot, productId);
      const result = checkoutResource.safeParse(checkoutData);

      if (result.success) {
        this.$patch({ checkout: checkoutData });
        transaction.patch();
      } else {
        console.error('meta validation failed');
      }

      loadingStore.unloading(...loaders);
    },

    /**
     * Update a person product choice.
     */
    async setPersonChoices(personIds: number[], slot: string, productId: number): Promise<void> {
      const loadingStore = useLoadingStore();
      const transaction = useTransaction();

      const loaders = loadingStore.loading('patching', 'changing_person_choice');

      const checkoutData = await api.checkout.setPersonChoices(this.id, personIds, slot, productId);
      const result = checkoutResource.safeParse(checkoutData);

      if (result.success) {
        this.$patch({ checkout: checkoutData });
        transaction.patch();
      } else {
        console.error('meta validation failed');
      }

      loadingStore.unloading(...loaders);
    },

    /**
     * Delete a person product choice
     */
    async deletePersonChoice(personId: number, slot: string, productId: number): Promise<void> {
      const loadingStore = useLoadingStore();
      const transaction = useTransaction();

      const loaders = loadingStore.loading('patching');

      const checkoutData = await api.checkout.deletePersonChoice(this.id, personId, slot, productId);
      const result = checkoutResource.safeParse(checkoutData);

      if (result.success) {
        this.$patch({ checkout: checkoutData });
        transaction.patch();
      } else {
        console.error('meta validation failed');
      }

      loadingStore.unloading(...loaders);
    },

    /**
     * Create a booking
     */
    async createBooking() {
      const validationStore = useValidationStore();
      const loadingStore = useLoadingStore();
      const personalStore = useCustomerStore();
      const cookie = useCookie();

      return new Promise<BookingResource>(async (resolve, reject) => {
        const loaders = loadingStore.loading('validating_booking');

        try {
          await this.save(); // Save the current checkout

          if (personalStore.authentication.action === 'login' && personalStore.authentication.access_token) {
            api.client.setAccessToken(personalStore.authentication.access_token);
          } else {
            api.client.delAccessToken();
          }

          let token_cookie = null;
          if (Cookies.get('_ga')) {
            token_cookie = Cookies.get('_ga')?.substring(6) || null;
          }

          const response = await api.checkout.createBooking(this.id, {
            personal_data: personalStore.personal,
            terms: personalStore.terms,
            comment: personalStore.comment,
            authentication: personalStore.authentication,
            user_agent: window.navigator.userAgent,
            screen_resolution: `${window.screen.availWidth}x${window.screen.availHeight}`,
            browser_language: navigator.language,
            token_cookie: token_cookie,
            session_cookie: cookie.getSessionCookie(config.theme),
            global_session_cookie: cookie.getSessionCookie('global')
          });

          resolve(response);
        } catch (error) {
          if (isAxiosError(error) && error.response?.data?.errors) {
            validationStore.setErrors(error.response.data.errors);
          }
          if (isAxiosError(error) && error.response?.status === 401) {
            validationStore.setErrors({'authentication': [error.response?.data.message]});
            api.client.delAccessToken();
          }
          reject(error);
        }

        loadingStore.unloading(...loaders);
      });
    },

    /**
     * Validate the checkout for a given step
     * @returns boolean true when no errors accur.
     */
    async validateStep(step: string | undefined) {
      const validationStore = useValidationStore();
      const loadingStore = useLoadingStore();

      validationStore.clearErrors();

      // Skip when step is undefined.
      if (!step) return Promise.resolve(true);

      return new Promise<boolean>(async (resolve, reject) => {
        const loaders = loadingStore.loading('validating_step');

        try {
          await api.checkout.validateCheckoutStep(this.id, step);
          await this.save();
          resolve(true);
        } catch (error) {
          if (isAxiosError(error) && error.response?.data?.errors) {
            validationStore.setErrors(error.response.data.errors);
          }
          reject(error);
        }

        loadingStore.unloading(...loaders);
      });
    },

    /**
     * Watch for changes
     */
    watchAndSaveChanges() {
      if (stopWatcher) stopWatcher();
      stopWatcher = this.$subscribe(mutation => {
        if (mutation.type === 'direct') {
          this.save();
        }
      });
    },
  },
});

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useFlowStore, import.meta.hot));
// }
