<template>
  <div :class="{ 'border-t border-gray-200': hasReceiptUpsellsAvailable }">
    <UpsellReceiptCard
      v-if="$flowstore.checkout.meta.upsells.receipt"
      v-for="product in $flowstore.checkout.meta.upsells.receipt.products"
      :key="product.id"
      :product="product"
    />
  </div>
</template>

<script lang="ts" setup>
  import { useFlowStore } from '@/stores/useFlowStore';
  import UpsellReceiptCard from '@/components/UpsellReceiptCard.vue';

  const { hasReceiptUpsellsAvailable } = useFlowStore();
</script>
