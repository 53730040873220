/**
 * Support for [].toReversed() Safari < 16
 */

if (!Array.prototype.toReversed) {
  console.log('Array.prototype.toReversed polyfill is active');

  Array.prototype.toReversed = function () {
    return Array.from(this).reverse();
  };
}
