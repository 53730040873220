import { z } from 'zod';
import { acceptHMRUpdate, defineStore } from 'pinia';

const stateSchema = z.object({
  patching: z.boolean(),
  changing_person_choice: z.boolean(),
  changing_main_product: z.boolean(),
  changing_date: z.boolean(),
  validating_step: z.boolean(),
  validating_booking: z.boolean(),
  login: z.boolean(),
});

export type State = z.infer<typeof stateSchema>;

export type Sections = keyof State;

export const useLoadingStore = defineStore('loading', {
  state: (): State => ({
    patching: false,
    changing_person_choice: false,
    changing_main_product: false,
    changing_date: false,
    validating_step: false,
    validating_booking: false,
    login: false,
  }),

  actions: {
    loading(...sections: Sections[]) {
      sections.forEach(section => { this[section] = true; });
      return sections;
    },

    unloading(...sections: Sections[]) {
      sections.forEach(section => { this[section] = false; });
      return sections;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
}
