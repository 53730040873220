import { bookingResource, checkoutResource, validationErrorResource, type BookingResource, type CheckoutResource, type ValidationErrorResource, type AddressLookupResource, addressLookupResource } from '@/api/resources';
import { cancelPendingRequest, get, post, put, del } from '@/api/client';
import { createBookingRequest, type CreateCartRequest, type CreateBookingRequest } from '@/api/requests';

/**
 * Create or get an existing new checkout.
 */
export async function createCheckout(data: CreateCartRequest) {
  const responseData = (await post<CheckoutResource>('checkout', data)).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Update an existing checkout.
 */
export async function updateCheckout(id: string, data: CheckoutResource) {
  const signal = cancelPendingRequest('checkout.updateCheckout');
  const responseData = (await put<CheckoutResource>(`checkout/${id}`, data.data, signal)).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Create a new booking.
 */
export async function createBooking(id: string, data: CreateBookingRequest) {
  const requestData = createBookingRequest.safeParse(data);
  if (!requestData.success) Promise.reject(requestData.error);

  const signal = cancelPendingRequest('checkout.createBooking');
  const responseData = (await post<BookingResource>(`checkout/${id}/book`, data, signal)).data;
  const result = bookingResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Add person choice
 */
export async function setPersonChoices(checkout_id: string, person_ids: number[], slot: string, product_id: number) {
  const signal = cancelPendingRequest(`checkout.setPersonChoices.${product_id}`);
  const responseData = (await put<CheckoutResource>(`checkout/${checkout_id}/choices/${slot}/product/${product_id}/persons`, { person_ids }, signal)).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Add person choice
 */
export async function setPersonChoice(checkout_id: string, person_id: number, slot: string, product_id: number) {
  const signal = cancelPendingRequest(`checkout.setPersonChoice.${product_id}`);
  const responseData = (await put<CheckoutResource>(`checkout/${checkout_id}/choices/${slot}/product/${product_id}/person/${person_id}`, { }, signal)).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Delete person choice
 */
export async function deletePersonChoice(checkout_id: string, person_id: number, slot: string, product_id: number) {
  const signal = cancelPendingRequest(`checkout.deletePersonChoice.${product_id}`);
  const responseData = (await del<CheckoutResource>(`checkout/${checkout_id}/choices/${slot}/product/${product_id}/person/${person_id}`, { }, signal)).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Create or get an existing new checkout.
 */
export async function validateCheckoutStep(checkout_id: string, step: string) {
  const signal = cancelPendingRequest('checkout.validateStep');
  const responseData = (await get<ValidationErrorResource>(`checkout/${checkout_id}/validate/${step}`, undefined, signal)).data;
  const result = validationErrorResource.safeParse(responseData);
  return result.success ? responseData.errors : null;
}

/**
 * Get address info
 */
export async function lookupAddress(zipCode: string, houseNumber: number, houseNumberAddition?: string) {
  const signal = cancelPendingRequest('checkout.lookupAddress');
  const responseData = (await get<AddressLookupResource>(`checkout/address/lookup`, { zipCode, houseNumber, houseNumberAddition }, signal)).data;
  const result = addressLookupResource.safeParse(responseData);
  return result.success ? responseData.data : null;
}

export default {
  createCheckout,
  updateCheckout,
  createBooking,
  setPersonChoices,
  deletePersonChoice,
  lookupAddress,
};
