<template>
  <footer class="container-inset sticky bottom-0 inset-x-0 bg-white z-30 shadow-[0_-1px_12px_0_rgba(0,0,0,0.06)]">
    <div class="container py-5">
      <ProgressIndicator :progress="navStore.totalProgress" class="absolute top-0 inset-x-0 lg:hidden" />
      <StepNavigation />
    </div>
  </footer>
</template>

<script lang="ts" setup>
  import { useNavigationStore } from '@/stores/useNavigationStore';
  import ProgressIndicator from '@/components/ProgressIndicator.vue';
  import StepNavigation from '@/partials/StepNavigation.vue';

  const navStore = useNavigationStore();
</script>
