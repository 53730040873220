import { nl, de, enUS, fr } from 'date-fns/locale';

export type Theme = 'bussloo' | 'soesterberg' | 'badnieuweschans' | 'berendonck' | 'maarssen' | 'maastricht'
export type Locale = 'nl-NL' | 'de-DE' | 'en-US' | 'fr-FR'

// Explode Locale type strings on "-" and collect only the first of the array.
export type Language = `${Extract<Locale, string> extends `${infer L}-${infer _}` ? L : Extract<Locale, string>}`

export interface Config {
  api: string
  baseUrl: string
  channel: string
  locale: Locale
  theme: Theme
  websiteUrl: string
}

export const config: Config = window.ReservationConfig || {};

export const dateLocale =
  config.locale === 'nl-NL' ? nl :
  config.locale === 'de-DE' ? de :
  config.locale === 'en-US' ? enUS :
  config.locale === 'fr-FR' ? fr :
  enUS;

export const language: Language = config.locale.split('-')[0] as Language;

export default {
  ...config,
  language,
  dateLocale,
};
