<template>
  <Card class="overflow-hidden">
    <div class="flex flex-col h-full">
      <span class="block flex-1">
        <span class="hidden lg:block rounded-t flex-none w-auto aspect-video overflow-hidden bg-qwr-haze-800">
          <Image :src="checkoutType.image" class="h-full w-full object-cover object-center" />
        </span>
        <span class="block px-6 py-4 lg:p-6">
          <span class="block text-xl lg:text-2xl font-serif mb-2">{{ checkoutType.name }}</span>
        </span>
      </span>
      <span class="flex px-6 py-4 border-t border-qwr-haze-100">
        <Button :variant="active ? 'primary' : 'light'" :active="true" class="w-full" @click="changeMainProduct">
          {{ active ? $t('selected') : $t('select') }}
        </Button>
      </span>
    </div>
  </Card>
</template>

<script lang="ts" setup>
  import { useFlowStore } from '@/stores/useFlowStore';
  import { useRouter } from 'vue-router';
  import Button from '@/components/Button.vue';
  import Card from '@/components/Card.vue';
  import Image from '@/components/Image.vue';
  import type { CheckoutTypeResource } from '@/api/resources';
  import { isNil, omitBy } from 'lodash';

  export interface Props {
    active?: boolean
    checkoutType: CheckoutTypeResource,
  }

  const props = withDefaults(defineProps<Props>(), {
    active: false,
  });

  const flowStore = useFlowStore();
  const router = useRouter();

  // This will trigger a flow reset defined in App.vue.
  const changeMainProduct = async () => {
    await router.push({
      name: 'reservation',
      query: omitBy({
        checkout_type: props.checkoutType.type,
        voucher_codes: flowStore.checkout.meta?.voucher_codes,
        arrival_date: flowStore.checkout.data?.arrival?.date,
        departure_date: flowStore.checkout.data?.departure?.date,
        persons_count: flowStore.checkout.data?.persons_count,
        bath_card_id: flowStore.checkout.meta?.bath_card_id,
      }, isNil),
    });
  };
</script>
