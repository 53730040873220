<template>
  <ul :class="{
    'grid gap-3 grid-flow-row': true,
    'sm:grid-cols-2': autoflow && filteredUsps.length >= 6,
  }">
    <li v-for="(usp, i) in filteredUsps" :key="i" class="relative pl-8 text-sm">
      <i v-if="usp.icon" :class="`${usp.icon} text-primary absolute text-base left-0`" />
      {{ usp.description }}
    </li>
  </ul>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import type { UspResource } from '@/api/resources';

  export interface Props {
    usps: UspResource[]
    autoflow?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    autoflow: false,
  });

  const filteredUsps = computed(() => props.usps.filter(usp => usp.description !== null));
</script>
