<template>
  <div class="flex justify-between select-none border border-gray-300 rounded-full bg-white items-center">
    <button data-cy="decrement" type="button" @click="decrement" class="bg-[#f0f0f0] rounded-full w-6 h-6 leading-1 m-1.5 hover:bg-qwr-dark-100 active:bg-qwr-dark-200" :class="{ 'invisible': !canDecrement }">
      <MinIcon class="w-6 h-4 pointer-events-none" />
    </button>
    <div>{{ constructedLabel }}</div>
    <button data-cy="increment" type="button" @click="increment" class="bg-[#f0f0f0] rounded-full w-6 h-6 leading-4 m-1.5 hover:bg-qwr-dark-100 active:bg-qwr-dark-200" :class="{ 'invisible': !canIncrement }">
      <PlusIcon class="w-6 h-6 pointer-events-none" />
    </button>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import MinIcon from 'thermen-theme/icons/min.svg?component';
  import PlusIcon from 'thermen-theme/icons/plus.svg?component';

  export interface Props {
    modelValue: number
    min?: number
    max?: number
    label?: string
  }

  const { t, te } = useI18n();

  const props = withDefaults(defineProps<Props>(), {
    min: 1,
    max: Number.MAX_SAFE_INTEGER,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const canDecrement = computed(() => props.modelValue > props.min);
  const canIncrement = computed(() => props.modelValue < props.max);

  const decrement = () => {
    if (props.modelValue > props.max) {
      return emit('update:modelValue', props.max);
    }

    emit('update:modelValue', props.modelValue - 1);
  };

  const increment = () => {
    if (props.modelValue < props.min) {
      return emit('update:modelValue', props.min);
    }

    emit('update:modelValue', props.modelValue + 1);
  };

  const constructedLabel = computed(() => {
    if (props.label && te(props.label)) {
      return t(props.label, props.modelValue);
    }
    return `${props.modelValue} ${props.label}`;
  });
</script>
