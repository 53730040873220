export type AppEvent = {
  'reservationflow.open': {
    url?: string
    checkout?: string
    product?: number
    voucher_code?: string // @deprecated since 8-4-2024
    voucher_codes?: string[]
    arrival_date?: string
    departure_date?: string
    bath_card_id?: number
    persons_count?: number
    access_token?: string
    checkout_type?: string
    lang?: string
    ga_origin?: string
  }
  'reservationflow.close'?: unknown
  'reservationflow.ready'?: unknown
  'mijnthermen.open'?: unknown
  'mijnthermen.close'?: unknown
  'mijnthermen.authenticate'?: {
    access_token?: string
  }
  'mijnthermen.authenticated'?: {
    access_token?: string
  }
  'reservationflow.authenticated'?: {
    access_token?: string
  }
  'reservationchoice.open': {
    workspace?: string
    voucherPopup?: boolean
  }
  'reservationchoice.close'?: unknown
  'reservationchoice.ready'?: unknown
  'redirect'?: {
    tag?: string
    url: string
  }
}

/**
 * Transforms each AppEvent into a callback funciton.
 */
export type AppEvents = {
  [K in keyof AppEvent]?: (data: AppEvent[K]) => void
}

/**
 * When application is intergrated via an iframe
 */
export const isIntergrated = window.self !== window.top;

/**
 * Messages event handler.
 */
const handleIncomingMessage = (source: string, events: AppEvents = {}) => (message: MessageEvent) => {
  if (!message.data) return;
  if (typeof message.data.event !== 'string') return;
  if (typeof message.data.source !== 'string') return;

  // Prevent receiving messages from you self.
  if (message.data.source === source) return;

  // Handle events directly bind the the receiveMessage function.
  const handler = events[message.data.event as keyof AppEvents];
  if (handler) handler(message.data.contents);

  // Handle events by dispatching custom events, these can be defined anywhere.
  const customEvent = new CustomEvent(message.data.event, { detail: message.data });
  window.dispatchEvent(customEvent);
};

let handle: ReturnType<typeof handleIncomingMessage> | undefined;

/**
 * Start receiving messages from other applications.
 * @param events
 */
export function receiveMessages(source: string, events: AppEvents = {}) {
  handle = handleIncomingMessage(source, events);
  window.addEventListener('message', handle);
}

/**
 * Stop receiving messages
 */
export function stopMessages() {
  if (handle) window.removeEventListener('message', handle);
}

/**
 * Send a message
 */
export function sendMessage<T extends keyof AppEvents>(source: string, event: T, contents: AppEvent[T] = {} as AppEvent[T]) {
  window.parent.postMessage({ event, source, contents }, '*');
}

// export const sendCloseMessage = () => sendMessage('reservationflow', 'reservationflow.close');

// window.addEventListener('redirect', event => {
//   const detail = (event as CustomEvent).detail as AppEvent['redirect'];
//   if (!detail) return;
//   window.location.href = detail.url;
// });
