import { ref, computed } from 'vue';
import { z } from 'zod';
import Cookies from "js-cookie";

const CookieList = z.record(z.string(), z.string())

/**
 * Keep track of patch transactions.
 * Used to prevent update component states multiple times.
 */
export default function useCookie() {
  const sessionCookieKeys = ref<z.infer<typeof CookieList>>({
    "global": "_ga_G8MSDVMV1X",
    "soesterberg": "_ga_Z867J7509E",
    "bussloo": "_ga_RPB96NLLEJ",
    "badnieuweschans": "_ga_JB3REBLX5D",
    "berendonck": "_ga_6DBT5GWBY0",
    'maarssen': '_ga_SV2WPLB3DJ',
    'maastricht': '_ga_T45QSJM139'
  });

  const getSessionCookie = (key: string): string|null => {
    return Cookies.get(sessionCookieKeys.value[key] || sessionCookieKeys.value['global']) || null;
  };

  return {
    sessionCookieKeys,
    getSessionCookie,
  };
}
