<template>
  <Card class="flex flex-col justify-between w-full">
    <div class="relative">
      <div v-if="product.is_available && product.slider.length" class="rounded-t aspect-[2/1] overflow-hidden bg-black">
        <Slider :settings="{ items: 1, nav: true, loop: true }">
          <div v-for="media in product.slider" class="aspect-[2/1]">
            <Image v-if="media.type === 'image'" :src="media.data.url" class="object-cover object-center h-full w-full" />
            <iframe v-if="media.type === 'youtube_embed'" :src="media.data.url" class="object-cover object-center h-full w-full" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </Slider>
      </div>
      <div class="px-6 pt-6 pb-3">
        <p class="uppercase text-primary text-sm mb-1 font-semibold tracking-wide">{{ $t('hotel_room_type', product.required_number_of_persons || 1) }}</p>
        <h3 class="text-xl font-serif mb-2">{{ product.name }}</h3>
      </div>
      <div class="relative px-6 pb-6 pt-3">
        <HtmlContent :html="product.description" strip class="mt-2 mb-8" />
        <button type="button" @click="modal?.openModal()" class="flex items-center uppercase text-sm font-semibold tracking-wider mt-1 mb-2">
          <HelpCircle class="mr-2 w-3.5" />
          {{ $t('more_information') }}
        </button>
        <div v-if="!product.is_available" class="absolute inset-0 p-4 flex items-center justify-center backdrop-blur-[2px] bg-stone-100/50">
          <span class="bg-white text-xs px-4 py-3 rounded-full shadow-md">{{ $t('notice_this_hotel_room_is_unavailable') }}</span>
        </div>
      </div>
    </div>
    <div class="relative">
      <ProductPersonSelection :group="group" :product="product" class="p-6 border-t border-qwr-haze-100" />
      <footer class="px-6 py-4 border-t border-qwr-haze-100">
        <div class="flex justify-between items-center">
          <Price :value="product.price" prefix="+" :affix="$t('per_person_short')" class="text-2xl font-semibold block" />
          <Button variant="light" @click="selectAllPersons">
            <span class="hidden lg:block">{{ $t('select_all') }}</span>
            <span class="lg:hidden">{{ $t('select') }}</span>
          </Button>
        </div>
      </footer>
    </div>

    <ProductDetailModal :product="product" :group="group" ref="modal" @select="handleSelectFromModal" />
  </Card>
</template>

<script lang="ts" setup>
  import { isEqual, cloneDeep } from 'lodash';
  import { ref, watch, onMounted } from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import Button from '@/components/Button.vue';
  import Card from '@/components/Card.vue';
  import HelpCircle from 'thermen-theme/icons/help-circle.svg?component';
  import HtmlContent from '@/components/HtmlContent.vue';
  import Image from '@/components/Image.vue';
  import Price from '@/components/Price.vue';
  import ProductDetailModal from '@/components/modals/ProductDetailModal.vue';
  import ProductPersonSelection from '@/components/ProductPersonSelection.vue';
  import Slider from '@/components/Slider.vue';
  import type { UpsellProductResource } from '@/api/resources';
  import useTransaction from '@/compositions/useTransaction';

  export interface Props {
    group: string,
    product: UpsellProductResource
  }

  const props = defineProps<Props>();

  const { setPersonChoices } = useFlowStore();
  const { requestPatch } = useTransaction(); // NOTE: Mogelijk overbodig geworden

  const persons = ref<{
    id: number
    name: string
    selected: boolean
  }[]>([]);

  const modal = ref<InstanceType<typeof ProductDetailModal>>()

  onMounted(() => {
    if (props.product.persons) {
      persons.value = cloneDeep(props.product.persons);
    }
  });

  watch(() => props.product.persons, (newValue, oldValue) => {
    if (newValue && !isEqual(oldValue, newValue)) {
      persons.value = cloneDeep(newValue);
    }
  }, { deep: true });

  const commitChanges = async () => {
    const selectedPersonIds: number[] = persons.value.filter(person => person.selected).map(person => person.id);

    if (props.product.id && props.product.is_available) {
      requestPatch();
      await setPersonChoices(selectedPersonIds, props.product.slot, props.product.id);
    }
  };

  const selectAllPersons = async () => {
    if (props.product.persons) {
      persons.value.forEach((person, index) => {
        // if (!props.product.required_number_of_persons || props.product.required_number_of_persons && index < props.product.required_number_of_persons) {
          person.selected = true;
        // }
      });
    }
    await commitChanges();
  };

  const handleSelectFromModal = async () => {
    modal.value?.closeModal();
    await selectAllPersons();
  };
</script>
