import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8 0C3.564 0 0 3.564 0 8s3.564 8 8 8 8-3.564 8-8-3.564-8-8-8m0 14.545A6.52 6.52 0 0 1 1.455 8 6.52 6.52 0 0 1 8 1.455 6.52 6.52 0 0 1 14.545 8 6.52 6.52 0 0 1 8 14.545m.873-10.763c-1.528-.51-3.2.29-3.71 1.745-.072.437.073.873.51.946.363.145.8-.073.945-.437.291-.727 1.091-1.163 1.891-.872.582.218.946.727.946 1.381 0 .728-1.237 1.31-1.673 1.528-.364.145-.582.509-.437.945a.7.7 0 0 0 .655.51c.073 0 .145 0 .218-.073.291-.073 2.691-.946 2.691-2.837-.073-1.309-.873-2.4-2.036-2.836M7.49 11.127a.66.66 0 0 0-.218.51.66.66 0 0 0 .218.508.66.66 0 0 0 .509.219.66.66 0 0 0 .51-.219.8.8 0 0 0 .217-.509.8.8 0 0 0-.218-.509.703.703 0 0 0-1.018 0"
    }, null, -1)
  ])))
}
export default { render: render }