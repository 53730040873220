import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "M12 3c.572 0 1.094.115 1.506.332s.759.577.759 1.055v.249h1.441a.41.41 0 0 1 .412.409v.818h1.853c.563 0 1.029.463 1.029 1.023v13.091c0 .56-.466 1.023-1.029 1.023H6.03a1.033 1.033 0 0 1-1.029-1.023V6.886c0-.56.466-1.023 1.029-1.023h1.853v-.818a.41.41 0 0 1 .412-.409h1.441v-.249c0-.478.347-.837.759-1.055S11.429 3 12.001 3zm-3.619 7.001c-.142.008-.271.111-.336.27s-.058.349.019.5c.077.15.212.238.355.229h7.178c.144.003.278-.092.35-.248s.072-.348 0-.504-.206-.25-.35-.248H8.382zm0 3c-.142.008-.271.111-.336.27s-.058.349.019.499.212.238.355.229h7.178c.144.003.278-.092.35-.248s.072-.348 0-.504-.206-.25-.35-.248H8.382zm.094 3a.498.498 0 0 0-.397.769c.096.15.265.238.443.229h6.973a.502.502 0 1 0 0-1h-7.02zM12 3.818c-.455 0-.862.1-1.119.237s-.322.268-.322.332v.658a.41.41 0 0 1-.412.409H8.706V7.09h6.588V5.454h-1.441a.41.41 0 0 1-.412-.409v-.658c0-.064-.064-.196-.322-.332s-.665-.237-1.119-.237"
    }, null, -1)
  ])))
}
export default { render: render }