import { ref, computed } from 'vue';

const iteration = ref<number>(0);

/**
 * Keep track of patch transactions.
 * Used to prevent update component states multiple times.
 */
export default function useTransaction() {
  const myIteration = ref<number>(0);

  const isPatched = () => {
    return iteration.value > myIteration.value;
  };

  return {
    iteration,
    myIteration,

    requestPatch: () => {
      myIteration.value = iteration.value + 1;
    },

    patch: () => {
      iteration.value += 1;
    },

    isPatched,

    patched: computed(() => isPatched()),
  };
}
