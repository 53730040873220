<template>
  <label class="inline-flex gap-2">
    <input
      :type="group ? 'radio' : 'checkbox'"
      :name="group ? group : undefined"
      :id="`checkfield_${uid}`"
      :checked="isChecked"
      :disabled="disabled"
      @input="commitChanges"
      @click="handleClick"
      :class="{
        'flex-none align-top appearance-none w-4 h-4 bg-[size:0.7rem] bg-no-repeat bg-center transition-colors border checked:bg-primary checked:border-primary checked:bg-checkbox': true,
        'border-qwr-dark-200': !hasError,
        'border-red-600 ': hasError,
      }"
    />
    <div v-if="slots.default" :class="{
      '-mt-1': true,
      'text-red-600 [&>a]:text-red-600 error-scroll-anchor': hasError,
    }"><slot /></div>
  </label>
</template>

<script lang="ts" setup>
  import { computed, useSlots, getCurrentInstance, ref, watch } from 'vue';

  export type Scalar = string | boolean | number;

  export interface Props {
    modelValue?: Scalar | Scalar[]
    onValue?: Scalar
    offValue?: Scalar
    group?: string
    disabled?: boolean
    errors?: string[]
  }

  const props = withDefaults(defineProps<Props>(), {
    onValue: true,
    offValue: false,
    disabled: false,
  });

  const slots = useSlots();

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const uid = getCurrentInstance()?.uid

  const isChanged = ref(false);

  const hasError = computed(() => props.errors && props.errors.length > 0 && !isChanged.value);

  watch(() => props.errors, () => {
    isChanged.value = false;
  });

  /**
   * When checkfield operates in group mode, (aka radiobutton).
   * It is impossible to uncheck this natively, this click handle is used for this case.
   */
  const handleClick = (event: Event) => {
    if (props.group && isChecked.value) {
      (event.target as HTMLInputElement).checked = false;
      commitChanges(event);
    }
  };

  const commitChanges = (event: Event) => {
    isChanged.value = true;

    if (Array.isArray(props.modelValue)) {
      const newValues = [...props.modelValue];
      const valueIndex = newValues.indexOf(props.onValue);

      if (valueIndex !== -1) {
        newValues.splice(valueIndex, 1);
      } else {
        newValues.push(props.onValue);
      }

      emit('update:modelValue', newValues);
    } else {
      emit('update:modelValue', (event.target as HTMLInputElement).checked ? props.onValue : props.offValue);
    }
  };

  const isChecked = computed(() => {
    return props.modelValue === props.onValue;
  });
</script>
