import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m5.88 5 3.853 3.853a.622.622 0 1 1-.88.88L5 5.88 1.147 9.733a.622.622 0 1 1-.88-.88L4.12 5 .267 1.147a.622.622 0 1 1 .88-.88L5 4.12 8.853.267a.622.622 0 1 1 .88.88z"
    }, null, -1)
  ])))
}
export default { render: render }