import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F4A14C",
      d: "M78.177 33.059c-.417-7.606-13.624-17.995-21.217-22.871 0 0 .326-5.294-5.568-4.936-4.096.25-4.818.153-4.878.07-.368.345-2.141 2.004-3.056 2.945q-.457.021-.933.06a36.5 36.5 0 0 0-6.801 1.625c-.04.02-.075.043-.114.064-.026.203-.06.566-.072 1.056-.08 3.247.915 11.949 13.37 14.762 1.019.23 1.941.485 2.792.753a27 27 0 0 1 1.894.676c3.345 1.353 5.106 3.004 5.955 4.58.03-4-.323-9.483-2.143-14.157 3.112 2.825 8.634 8.262 13.405 15.162 0 0-4.339 4.887-12.37 2.799-2.732-1.417-5.278-3.425-6.342-7.089a31 31 0 0 0-3.576-1.023c-8.715-1.97-12.239-6.73-13.66-10.376a15.7 15.7 0 0 1-.987-4.127c-.513 1.293-.28 2.74-.058 4.08.153.934.287 1.74.145 2.429-.296 1.433-1.132 3.238-1.646 4.263q.256.21.45.498c.57.848.586 2.015.524 2.731a2.93 2.93 0 0 1 1.092 1.917l.003.049c.064 1.374.547 2.07 1.432 2.07.097 0 .157-.01.158-.01l.044-.006 5.895-.756a.89.89 0 0 1 .691.215s2.072 1.11 2.072 4.63c0 0-.398 3.537-4.035 3.996s-6.959.299-8.438 2.672c-1.483 2.373-.405 10.995-1.887 16.922 0 0-.99 13.159-2.203 16.527l6.601.172s4.29-12.05 4.302-21.517c0 0 3.948 6.968 2.989 15.186 0 0-.899 4.042-2.438 6.387 1.662.658 8.311 2.526 14.661 2.54-2.66-14.603-1.079-27.166-1.079-27.166-1.217 12.226 1.869 22.969 3.132 26.986 2.907-.32 5.933-1.093 8.863-2.588 0 0-6.775-9.715-1.586-22.924 2.612-6.647 15.04-11.575 14.617-19.276"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F4A14C",
      d: "M42.928 79.298c-13.724-5.33-23.756.874-27.185 6.332 0 0 16 .927 30.518 7.532 16.342 7.434 25.763-5.785 20.413-15.135 0 0-10.023 6.6-23.746 1.271"
    }, null, -1)
  ])))
}
export default { render: render }