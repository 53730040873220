<template>
  <Card class="flex flex-col justify-between w-full">
    <div>
      <div class="rounded-t aspect-video overflow-hidden bg-qwr-haze-800 relative">
        <Sticker :visible="product.sticker?.visible || false" :text="product.sticker?.text" />
        <Image :src="product.main_image_url" class="h-full w-full object-cover" />
      </div>
      <div class="p-6">
        <span class="text-xl font-serif block mb-2">{{ product.name }}</span>
        <button type="button" @click="modal?.openModal()" class="flex items-center uppercase text-sm font-semibold tracking-wider mt-1 mb-2">
          <HelpCircle class="mr-2 w-3.5" />
          {{ $t('more_info') }}
        </button>
      </div>
    </div>
    <div class="relative">
      <!-- FAKE duration, this does literally nothing! -->
      <div v-if="product.duration" class="px-6 pt-6 pb-4 border-t border-gray-200">
        <span class="text-xs font-semibold block mb-2">{{ $t('how_long') }}</span>
        <SelectField modelValue="0" :options="[{
          value: 0,
          label: product.duration + (!product.is_available ? ` (${t('unavailable')})`: ''),
        }]" variant="outlined" />
      </div>
      <ProductPersonSelection
        :group="group"
        :product="product"
        class="px-6 pb-6 border-b border-qwr-haze-100"
      />
      <div class="p-6">
        <span class="text-sm block">{{ $t('per_person_short') }}</span>
        <Price :value="product.price" class="text-2xl font-semibold	block"/>
      </div>
      <div v-if="!product.is_available" class="absolute inset-0 p-4 flex items-center justify-center backdrop-blur-[2px] bg-stone-100/50">
        <span class="bg-white text-xs px-4 py-3 rounded-full shadow-md">{{ $t('notice_chosen_date_or_time_is_unavailable') }}</span>
      </div>
    </div>

    <ProductDetailModal :group="group" :product="product" ref="modal" @select="handleSelectFromModal" />
  </Card>
</template>

<script lang="ts" setup>
  import { isEqual, cloneDeep } from 'lodash';
  import { ref, watch, onMounted } from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import { useI18n } from 'vue-i18n';
  import Button from '@/components/Button.vue';
  import Card from '@/components/Card.vue';
  import HelpCircle from 'thermen-theme/icons/help-circle.svg?component';
  import Image from '@/components/Image.vue';
  import Price from '@/components/Price.vue';
  import ProductDetailModal from '@/components/modals/ProductDetailModal.vue';
  import ProductPersonSelection from '@/components/ProductPersonSelection.vue';
  import SelectField from '@/components/fields/SelectField.vue';
  import type { UpsellProductResource } from '@/api/resources';
  import useTransaction from '@/compositions/useTransaction';
  import Sticker from "@/components/Sticker.vue";

  export interface Props {
    group?: string,
    product: UpsellProductResource
  }

  const props = defineProps<Props>();

  const { setPersonChoices } = useFlowStore();
  const { requestPatch } = useTransaction(); // NOTE: Mogelijk overbodig geworden
  const { t } = useI18n();

  const persons = ref<{
    id: number
    name: string
    selected: boolean
  }[]>([]);

  const modal = ref<InstanceType<typeof ProductDetailModal>>()

  onMounted(() => {
    if (props.product.persons) {
      persons.value = cloneDeep(props.product.persons);
    }
  });

  watch(() => props.product.persons, (newValue, oldValue) => {
    if (newValue && !isEqual(oldValue, newValue)) {
      persons.value = cloneDeep(newValue);
    }
  }, { deep: true });

  const commitChanges = async () => {
    const selectedPersonIds: number[] = persons.value.filter(person => person.selected).map(person => person.id);

    if (props.product.id && props.product.is_available) {
      requestPatch();
      await setPersonChoices(selectedPersonIds, props.product.slot, props.product.id);
    }
  };

  const selectAllPersons = () => {
    if (props.product.persons) {
      persons.value.forEach(person => person.selected = true);
    }
  };

  const handleSelectFromModal = async () => {
    modal.value?.closeModal();
    selectAllPersons();
    await commitChanges();
  };
</script>
