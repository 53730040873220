<template>
  <div class="relative">
    <input
      type="text"
      :name="name"
      :id="`textfield_${uid}`"
      :value="modelValue"
      :disabled="disabled"
      :autocomplete="autocomplete"
      placeholder=" "
      :data-cy="name ? `input_${name}` : null"
      @input="commitChanges"
      :class="tw(
        'block w-full py-2.5 px-0 text-sm  bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 peer',
        !hasError && 'text-qwr-dark border-qwr-dark-200 focus:border-primary',
        hasError && 'text-red-600 border-red-600',
        disabled && 'text-qwr-dark-400',
      )"
    />
    <label :for="`textfield_${uid}`" :class="{
      'absolute text-sm text-qwr-dark duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6': true,
      'text-red-600': hasError,
    }">
      <slot/>
    </label>
    <ErrorBag :errors="errors" :isNotice="isChanged" />
  </div>
</template>

<script lang="ts" setup>
  import { computed, getCurrentInstance, ref, watch } from 'vue';
  import { twMerge as tw } from 'tailwind-merge';
  import ErrorBag from '@/components/ErrorBag.vue';

  export interface Props {
    name?: string
    placeholder?: string
    modelValue?: string | null
    disabled?: boolean
    errors?: string[]
    autocomplete?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
  });

  const uid = getCurrentInstance()?.uid

  const isChanged = ref(false);

  const hasError = computed(() => props.errors && props.errors.length > 0 && !isChanged.value);

  watch(() => props.errors, () => {
    isChanged.value = false;
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const commitChanges = (event: Event) => {
    isChanged.value = true;
    emit('update:modelValue', (event.target as HTMLInputElement).value);
  };
</script>
