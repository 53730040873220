import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 50 50"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M25 0c13.807 0 25 11.193 25 25S38.807 50 25 50 0 38.807 0 25 11.193 0 25 0m4 21H17v4h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2v4h16v-4h-1.992c-1.102 0-2-.895-2-2zM25 9a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
    }, null, -1)
  ])))
}
export default { render: render }