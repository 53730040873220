<template>
  <div class="relative">
    <div class="relative">
      <select :autocomplete="autocomplete" :id="id" :value="modelValue" :disabled="disabled" @change="commitChanges" :class="{
        'w-full appearance-none': true,
        'text-center': align === 'center',
        'text-left': align === 'left',

        // Underlined variant
        'py-2.5 px-0 text-sm bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0': variant === 'underlined',
        'text-qwr-dark border-qwr-dark-200 focus:border-primary': variant === 'underlined' && !hasError,
        'text-red-600 border-red-600': variant === 'underlined' && hasError,

        // Outlined variant
        'border rounded-full bg-white items-center py-1.5 px-4': variant === 'outlined',
        'border-gray-300': variant === 'outlined' && !hasError,
        'border-red-600': variant === 'outlined' && hasError,
      }">
        <option v-if="optional" disabled value="" selected>{{ placeholder ? placeholder : $t('select') }}</option>
        <option v-for="{ label, value, disabled } in options" :value="value" :key="value" :disabled="disabled">{{ label }}</option>
      </select>

      <FilterIcon class="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2" />
    </div>
    <label v-if="variant === 'underlined'" :for="`selectfield${uid}`" class="absolute text-sm text-qwr-dark duration-300 transform top-3 origin-top-left left-0 scale-75 -translate-y-6">
      <slot/>
    </label>
    <ErrorBag :errors="errors" :isNotice="isChanged"/>
  </div>
</template>

<script lang="ts" setup>
  import { computed, getCurrentInstance, ref, watch } from 'vue';
  import ErrorBag from '@/components/ErrorBag.vue';
  import FilterIcon from 'thermen-theme/icons/filter-icon.svg?component';

  export interface Option {
    value: string | number
    label: string | number
    disabled?: boolean
  }

  export interface Props {
    id?: string
    modelValue?: string | null
    options: Option[]
    optional?: boolean
    disabled?: boolean
    variant?: 'underlined' | 'outlined'
    align?: 'left' | 'center'
    errors?: string[]
    autocomplete?: string
    placeholder?: string | null
  }

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    optional: true,
    variant: 'underlined',
    align: 'left',
  });

  const uid = getCurrentInstance()?.uid

  const isChanged = ref(false);

  const hasError = computed(() => props.errors && props.errors.length > 0 && !isChanged.value);

  watch(() => props.errors, () => {
    isChanged.value = false;
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const commitChanges = (event: Event) => {
    isChanged.value = true;
    emit('update:modelValue', (event.target as HTMLSelectElement).value);
  };
</script>
