import * as client from '@/api/client';
import * as checkout from '@/api/endpoints/checkout';
import calendar from "@/api/endpoints/calendar";
import * as receipt from "@/api/endpoints/receipt";
import * as account from "@/api/endpoints/account";

export default {
  client,
  checkout,
  receipt,
  calendar,
  account,
};
