<template>
  <div class="grid grid-cols-1 transition-opacity overflow-clip relative z-0" :class="{ 'opacity-0': !slider }">
    <header v-if="header" class="flex gap-4 justify-between items-center mb-6 select-none">
      <div class="min-w-10">
        <button v-if="count > 1 && settings && (settings.loop || !settings.loop && info && info.index > 0)" type="button" @click="slider?.goTo('prev')" class="bg-white shadow w-10 h-10 rounded-full flex justify-center items-center flex-none">
          <ChevronIcon class="h-2.5 flex-none text-qwr-dark-400 rotate-180" />
        </button>
      </div>
      <div>
        <slot name="header" :count="count">
          <div v-html="$t('you_have_number_of_choices', { n: count })"></div>
        </slot>
      </div>
      <div class="min-w-10">
        <button v-if="count > 1 && settings && (settings.loop || !settings.loop && info && info.index + info.items < info.slideCount)" type="button" @click="slider?.goTo('next')" class="bg-white shadow w-10 h-10 rounded-full flex justify-center items-center flex-none">
          <ChevronIcon class="h-2.5 flex-none text-qwr-dark-400" />
        </button>
      </div>
    </header>

    <template v-if="!header">
      <button v-if="count > 1 && settings && (settings.loop || !settings.loop && info && info.index > 0)" type="button" @click="slider?.goTo('prev')" class="absolute z-10 top-1/2 -translate-y-1/2 left-4 bg-white shadow w-10 h-10 rounded-full flex justify-center items-center flex-none">
        <ChevronIcon class="h-2.5 flex-none text-qwr-dark-400 rotate-180" />
      </button>
      <button v-if="count > 1 && settings && (settings.loop || !settings.loop && info && info.index + info.items < info.slideCount)" type="button" @click="slider?.goTo('next')" class="absolute z-10 top-1/2 -translate-y-1/2 right-4 bg-white shadow w-10 h-10 rounded-full flex justify-center items-center flex-none">
        <ChevronIcon class="h-2.5 flex-none text-qwr-dark-400" />
      </button>
    </template>

    <div :class="[
      '[&_.tns-liveregion]:z-0',
      '[&_.tns-nav]:absolute [&_.tns-nav]:z-10 [&_.tns-nav]:inset-x-0 [&_.tns-nav]:bottom-2 [&_.tns-nav]:flex [&_.tns-nav]:gap-3 [&_.tns-nav]:justify-center [&_.tns-nav]:items-center',
      '[&_.tns-nav_button]:h-3 [&_.tns-nav_button]:w-3 [&_.tns-nav_button]:bg-white/50 [&_.tns-nav_button]:rounded-full',
      '[&_.tns-nav_.tns-nav-active]:h-4 [&_.tns-nav_.tns-nav-active]:w-4 [&_.tns-nav_.tns-nav-active]:bg-white',
      '[&_.tns-slider]:flex [&_.tns-slider_.tns-item]:!flex',
    ]">
      <div ref="container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  /**
   * NOTE: grid grid-cols-1 is a hack to recompute the correct width of the app flex layout.
   */

  import 'tiny-slider/dist/tiny-slider.css';
  import { computed, ref, onMounted, onUnmounted, nextTick } from 'vue';
  import { tns, type TinySliderInstance, type TinySliderSettings, type TinySliderInfo } from 'tiny-slider/src/tiny-slider';
  import ChevronIcon from 'thermen-theme/icons/chevron.svg?component';

  export interface Props {
    settings?: TinySliderSettings
    header?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    header: false,
  });

  const container = ref<HTMLElement>();
  const slider = ref<TinySliderInstance>();
  const info = ref<TinySliderInfo>();
  const timeout = ref();

  let responsive = {};
  if (props.settings?.responsive === undefined && props.settings?.items && props.settings.items > 1) {
    responsive = {
      '0': { items: 1 }, // '0' key must be a string, else the key is omitted.
      '1200': { items: props.settings.items },
    };
  }

  function onIndexChanged(tsi: TinySliderInfo) {
    info.value = tsi;
  }

  onMounted(async () => {
    await nextTick();
    timeout.value = setTimeout(() => {
      if (!container.value) return;

      slider.value = tns({
        preventScrollOnTouch: 'auto',
        container: container.value,
        mouseDrag: true,
        lazyload: true,
        controls: false,
        swipeAngle: 20,
        loop: false, /* Looping does not working properly with interactive vue components */
        rewind: props.settings?.loop ? false : true,
        responsive,
        ...props.settings,
      });

      info.value = slider.value.getInfo();

      slider.value.events.on('indexChanged', onIndexChanged);
      slider.value.events.on('newBreakpointEnd', onIndexChanged);
    }, 200); // fixes page transition bug...
  });

  onUnmounted(() => {
    clearTimeout(timeout.value);
    if (slider.value) {
      slider.value.events.off('indexChanged', onIndexChanged);
      slider.value.events.off('newBreakpointEnd', onIndexChanged);
      slider.value.destroy();
      delete slider.value;
    }
  });

  const count = computed(() => info.value ? info.value.slideCount : 0);
</script>
