import nl_NL from '@/translations/nl-NL';
import de_DE from '@/translations/de-DE';
import en_US from '@/translations/en-US';
import fr_FR from '@/translations/fr-FR';

export type { MessageSchema } from '@/translations/nl-NL';

export const messages = {
  'nl-NL': nl_NL,
  'de-DE': de_DE,
  'en-US': en_US,
  'fr-FR': fr_FR,
} as const;

export type Locales = keyof typeof messages;
