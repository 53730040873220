<template>
  <div v-if="product.is_available">
    <span class="text-xs font-semibold block mb-2">{{ $t('for_who') }}</span>
    <div class="grid grid-cols-2 gap-2">
      <CheckField
        v-for="person in persons"
        :key="person.id"
        :group="group ? `${group}_for_guest_${person.id}`: undefined"
        :data-cy="`upsell-product-card-checkfield-product-${product.id}-person-${person.id}`"
        :modelValue="person.selected"
        @update:modelValue="handleUpdatePerson(person.id, $event)"
      >
        {{ person.name }}
      </CheckField>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { isEqual, cloneDeep } from 'lodash';
  import { ref, watch, onMounted } from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import CheckField from '@/components/fields/CheckField.vue';
  import type { UpsellProductResource } from '@/api/resources';

  export interface Props {
    group?: string,
    product: UpsellProductResource
  }

  const props = defineProps<Props>();

  const { setPersonChoice, deletePersonChoice } = useFlowStore();

  const persons = ref<{
    id: number
    name: string
    selected: boolean
  }[]>([]);

  onMounted(() => {
    if (props.product.persons) {
      persons.value = cloneDeep(props.product.persons);
    }
  });

  watch(() => props.product.persons, (newValue, oldValue) => {
    if (newValue && !isEqual(oldValue, newValue)) {
      persons.value = cloneDeep(newValue);
    }
  }, { deep: true });

  const findPersonById = (personId: number) => {
    return persons.value.find(person => person.id === personId);
  };

  const handleUpdatePerson = async (personId: number, state: boolean) => {
    const person = findPersonById(personId);
    if (!person) return;

    person.selected = state;

    if (state) {
      await setPersonChoice(person.id, props.product.slot, props.product.id);
    } else {
      await deletePersonChoice(person.id, props.product.slot, props.product.id);
    }
  };
</script>
