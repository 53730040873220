<template>
  <img :src="src ? src : fallback" :alt="alt" :loading="lazy ? 'lazy' : null" @error.once="handleError" />
</template>

<script lang="ts" setup>
  export interface Props {
    src?: string | null
    alt?: string
    fallback?: string
    lazy?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    alt: 'photo',
    fallback: '/theme/assets/fallback.jpg',
    lazy: true,
  });

  const handleError = (payload: Event) => {
    (payload.target as HTMLImageElement).src = props.fallback;
  };
</script>
