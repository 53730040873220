<template>
  <textarea
    ref="field"
    :id="`textareafield_${uid}`"
    :placeholder="placeholder"
    :value="modelValue ?? undefined"
    :disabled="disabled"
    @input="commitChanges"
    :class="{
      'resize-y border w-full rounded p-1.5 min-h-[6rem]': true,
      'border-qwr-dark-200': !hasError,
      'border-red-600': hasError,
    }"
  ></textarea>
  <ErrorBag :errors="errors" :isNotice="isChanged" />
</template>

<script lang="ts" setup>
  import { computed, getCurrentInstance, ref, watch } from 'vue';
  import ErrorBag from '@/components/ErrorBag.vue';

  export interface Props {
    placeholder?: string
    modelValue?: string | null
    disabled?: boolean
    errors?: string[]
  }

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
  });

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const field = ref<HTMLTextAreaElement>();

  const uid = getCurrentInstance()?.uid

  const isChanged = ref(false);

  const hasError = computed(() => props.errors && props.errors.length > 0 && !isChanged.value);

  watch(() => props.errors, () => {
    isChanged.value = false;
  });

  const commitChanges = (event: Event) => {
    isChanged.value = true;
    emit('update:modelValue', (event.target as HTMLTextAreaElement).value);
  };

  const focus = () => {
    field.value?.focus();
    field.value?.setSelectionRange(0, 0);
  };

  defineExpose({
    focus,
  });
</script>
