import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"#DCDCDC\" fill-rule=\"nonzero\"><g transform=\"translate(0 2)\"><path d=\"M4.59 3.033 4.147 3a2.2 2.2 0 0 1-1.27.417c-.477 0-.918-.15-1.27-.417l-.442.033c-.688.034-1.2.6-1.164 1.234l.23 3.783c.034.483.458.867.97.867h.07l.424 5.883c.035.55.37.85.776.85h.811c.406 0 .741-.3.777-.85l.423-5.883h.07c.512 0 .954-.384.971-.867l.23-3.783c.035-.634-.494-1.184-1.165-1.234\"></path><ellipse cx=\"2.729\" cy=\"1.633\" rx=\"1.729\" ry=\"1.633\"></ellipse></g><g transform=\"translate(4)\"><path d=\"M6.59 5.033 6.147 5a2.2 2.2 0 0 1-1.27.417c-.477 0-.918-.15-1.27-.417l-.442.033c-.688.034-1.2.6-1.164 1.234l.23 3.783c.034.483.458.867.97.867h.07l.424 5.883c.035.55.37.85.776.85h.811c.406 0 .741-.3.777-.85l.423-5.883h.07c.512 0 .954-.384.971-.867l.23-3.783c.035-.634-.494-1.184-1.165-1.234\"></path><ellipse cx=\"4.729\" cy=\"3.633\" rx=\"1.729\" ry=\"1.633\"></ellipse><ellipse cx=\"1.729\" cy=\"1.633\" rx=\"1.729\" ry=\"1.633\"></ellipse></g></g>", 1)
  ])))
}
export default { render: render }