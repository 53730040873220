<template>
  <div v-if="contents" ref="container" :class="{
    'flex flex-col gap-4': true,
    '[&_a]:underline [&_a]:text-primary': !strip,
    '[&_strong]:font-semibold': !strip,
    '[&_ul]:list-inside [&_ul]:list-disc [&_ul]:pl-0.5': !strip,
    '[&_ol]:list-inside [&_ol]:list-decimal [&_ol]:pl-0.5': !strip,
  }" v-html="contents"></div>
</template>

<script lang="ts" setup>
  import { computed, ref, onMounted } from 'vue';

  export type Props = {
    html?: string | null
    strip?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    strip: false,
  });

  const container = ref<HTMLElement>();

  const contents = computed(() => {
    if (!props.html) return null;
    return props.strip ? props.html.replace(/(<([^>]+)>)/gi, '') : props.html;
  });

  onMounted(() => {
    if (container.value) {
      container.value.querySelectorAll('a').forEach(anchor => {
        anchor.setAttribute('target', '_blank');
      });
    }
  });
</script>
