import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5 3.448v17.104a.447.447 0 0 0 .748.329l1.551-1.437a.443.443 0 0 1 .599-.005l1.016.912a1.33 1.33 0 0 0 1.786-.005l1.002-.908a.443.443 0 0 1 .597 0l1.002.908a1.33 1.33 0 0 0 1.786.005l1.017-.912a.443.443 0 0 1 .599.005l1.551 1.437a.44.44 0 0 0 .481.081.45.45 0 0 0 .267-.41V3.448c0-.247-.2-.448-.446-.448H5.447a.447.447 0 0 0-.446.448zM9.404 9h5.191c.223 0 .404.224.404.5s-.181.5-.404.5H9.404C9.181 10 9 9.776 9 9.5s.181-.5.404-.5m0 3h5.191c.223 0 .404.224.404.5s-.181.5-.404.5H9.404C9.181 13 9 12.776 9 12.5s.181-.5.404-.5"
    }, null, -1)
  ])))
}
export default { render: render }