import { calendarResource, type CalendarResource } from '@/api/resources';
import { cancelPendingRequest, get } from '@/api/client';

export async function getCalendar(checkout_id: string, year: number, month: number, signalGroup: string = 'getCalendar'): Promise<CalendarResource> {
  const signal = cancelPendingRequest(`calendar.${signalGroup}`);
  const responseData = (await get<CalendarResource>(`checkout/${checkout_id}/calendar/${month}/${year}`, undefined, signal)).data;

  const result = calendarResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

export default {
  get: getCalendar,
};
