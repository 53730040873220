import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 768 768"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "M224 160h320c53.019 0 96 42.981 96 96v320c0 53.019-42.981 96-96 96H224c-53.019 0-96-42.981-96-96V256c0-53.019 42.981-96 96-96"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M208 352h352c8.837 0 16 7.163 16 16s-7.163 16-16 16H208c-8.836 0-16-7.163-16-16s7.163-16 16-16"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "M272 96h224c8.837 0 16 7.163 16 16s-7.163 16-16 16H272c-8.837 0-16-7.163-16-16s7.163-16 16-16"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32M544 256c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32"
    }, null, -1)
  ])))
}
export default { render: render }