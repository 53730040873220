import { z } from 'zod';
import { acceptHMRUpdate, defineStore } from 'pinia';

const stateSchema = z.object({
  errors: z.record(
    z.string(),
    z.array(z.string())
  ),
});

export type State = z.infer<typeof stateSchema>;

export const useValidationStore = defineStore('validation', {
  state: (): State => ({
    errors: {},
  }),

  getters: {
    hasErrors(state) {
      return Object.keys(state.errors).length > 0;
    },

    errorsExcept(state) {
      return (rules: string[]) => Object.fromEntries(
        Object.entries(state.errors).filter(([rule]) => !rules.includes(rule))
      );
    },

    errorsExceptFlat() {
      return (rules: string[]) => Object.values(this.errorsExcept(rules)).flatMap(errors => errors);
    },
  },

  actions: {
    clearErrors() {
      this.errors = {};
    },

    setErrors(errors: Record<string, string[]> = {}) {
      this.errors = errors;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useValidationStore, import.meta.hot));
}
