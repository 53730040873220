import config from '@/config';
import axios, { type RawAxiosRequestHeaders } from 'axios';

const API_BASE_URL = config.api;

const abortControllers = new Map<string, AbortController>();

const headers: RawAxiosRequestHeaders = {
  'Accept': 'application/json',
  'Accept-Language': config.locale,
};

const defaultParams =  {
  channel: config.channel,
};

export function delAccessToken() {
  if (headers.Authorization) delete headers.Authorization;
}

export function setAccessToken(token: string) {
  headers.Authorization = `Bearer ${token}`;
}

export async function get<ResponseType = unknown>(path: string, params: { [key: string]: unknown } = {}, signal?: AbortSignal) {
  return await axios.get<ResponseType>(`${API_BASE_URL}/${path}`, {
    params: { ...defaultParams, ...params },
    headers,
    signal,
  });
}

export async function post<ResponseType = unknown>(path: string, data: unknown = {}, signal?: AbortSignal) {
  return await axios.post<ResponseType>(`${API_BASE_URL}/${path}`, data, {
    params: defaultParams,
    headers,
    signal,
  });
}

export async function put<ResponseType = unknown>(path: string, data: unknown = {}, signal?: AbortSignal) {
  return await axios.put<ResponseType>(`${API_BASE_URL}/${path}`, data, {
    params: defaultParams,
    headers,
    signal,
  });
}

export async function del<ResponseType = unknown>(path: string, data: unknown = {}, signal?: AbortSignal) {
  return await axios.delete<ResponseType>(`${API_BASE_URL}/${path}`, {
    params: defaultParams,
    data,
    headers,
    signal,
  });
}

export function cancelPendingRequest(key: string): AbortSignal {
  const previous = abortControllers.get(key);
  if (previous) previous.abort();

  abortControllers.set(key, new AbortController());
  return abortControllers.get(key)!.signal;
}
