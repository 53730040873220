import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" transform=\"translate(5 3)\"><circle cx=\"10.5\" cy=\"8.5\" r=\"1\" fill=\"#323232\" stroke=\"#222\"></circle><g fill=\"#DCDCDC\"><circle cx=\"11\" cy=\"2\" r=\"2\"></circle><path d=\"M13.275 10.333h.06c.415 0 .732-.333.653-.722l-.773-4.889c-.059-.389-.396-.685-.831-.722h-.02a2.4 2.4 0 0 1-1.367.407c-.514 0-.97-.148-1.366-.407h-.02a.92.92 0 0 0-.831.722l-.773 4.89c-.06.37.258.721.654.721h.06l-.555 2.056c-.04.24.158.481.435.481l.97.056.318 4.315c.04.426.415.759.87.759h.496c.455 0 .832-.333.871-.76l.317-4.314.97-.056c.278-.018.476-.24.436-.481zM4.784 4.037 4.324 4A2.2 2.2 0 0 1 3 4.461 2.1 2.1 0 0 1 1.676 4l-.46.037A1.294 1.294 0 0 0 .002 5.402L.24 9.589c.037.535.478.96 1.012.96h.073l.442 6.51c.036.609.386.941.809.941h.846c.423 0 .773-.332.81-.94l.44-6.512h.074c.534 0 .994-.424 1.012-.96L6 5.403c.036-.701-.516-1.31-1.215-1.365Z\"></path><circle cx=\"3\" cy=\"2\" r=\"2\"></circle></g></g>", 1)
  ])))
}
export default { render: render }