<template>
  <header class="container-inset sticky top-0 inset-x-0 bg-white h-[4.3rem] lg:h-20 flex items-center z-30 shadow-[0_2px_4px_0_rgba(0,0,0,0.08)]">
    <div class="container flex justify-between items-center w-full">
      <button type="button" class="flex justify-start items-center w-10 h-10 lg:hidden" :class="{'invisible': !navStore.previous}" @click="navStore.gotoPreviousStep()">
        <Arrow class="w-4 pointer-events-none" />
      </button>

      <a :href="config.websiteUrl" @click="handleGoToWebsiteClick" class="block" :title="$t('go_to_the_website')">
        <span class="w-12 h-12 lg:w-auto relative overflow-hidden block">
          <Logo class="h-12" />
        </span>
      </a>

      <button type="button" @click="handleGoToWebsiteClick" :title="$t('go_to_the_website')" :class="{'invisible': !isIntergrated}" class="flex justify-end items-center w-10 h-10">
        <Cross class="w-4 pointer-events-none" />
      </button>
    </div>
  </header>
</template>

<script lang="ts" setup>
  import config from '@/config';
  import { isIntergrated, sendMessage } from '@/lib/comm';
  import { useNavigationStore } from '@/stores/useNavigationStore';
  import Arrow from 'thermen-theme/icons/arrow.svg?component';
  import Button from '@/components/Button.vue';
  import Cross from 'thermen-theme/icons/cross.svg?component';
  import Logo from '@/components/Logo.vue';

  const navStore = useNavigationStore();

  const handleGoToWebsiteClick = (event: MouseEvent) => {
    if (event) event.preventDefault();

    sendMessage('reservationflow', 'reservationflow.close');

    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage('reservationflow.close');
      return;
    }

    if (!isIntergrated) window.location.href = config.websiteUrl;
  };
</script>
