import { loginResource, type LoginResource, meResource, type MeResource, type ForgotPasswordResource, forgotPasswordResource } from '@/api/resources';
import { cancelPendingRequest, post, get} from '@/api/client';

/**
 * Login
 */
export async function login(email: string, password: string) {
  const signal = cancelPendingRequest('account.login');
  const responseData = (await post<LoginResource>(`auth/member/login`, { email, password }, signal)).data;
  const result = loginResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Me
 */
export async function me() {
  const signal = cancelPendingRequest('account.me');
  const responseData = (await get<MeResource>(`auth/member/me`, { }, signal)).data;
  const result = meResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

/**
 * Forgot password
 * @param email
 */
export async function forgotPassword(email: string) {
  const signal = cancelPendingRequest('account.forgot-password');
  const responseData = (await post<ForgotPasswordResource>(`auth/member/forgot-password`, { email }, signal)).data;
  const result = forgotPasswordResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}

export default {
  login,
  me,
  forgotPassword,
};
