import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "M11.5 3a8.5 8.5 0 1 1-.001 17.001A8.5 8.5 0 0 1 11.5 3"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M11.471 11h3.059c.26 0 .471.211.471.471v.059c0 .26-.211.471-.471.471h-3.059A.47.47 0 0 1 11 11.53v-.059c0-.26.211-.471.471-.471"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M11 11.529V7.47c0-.26.211-.471.471-.471h.059c.26 0 .471.211.471.471v4.059c0 .26-.211.471-.471.471h-.059a.47.47 0 0 1-.471-.471"
    }, null, -1)
  ])))
}
export default { render: render }