import { defineStore } from 'pinia';
import { isAxiosError } from 'axios';
import { persist } from '@/stores';
import { personalDataResource } from '@/api/resources';
import { sendMessage } from '@/lib/comm';
import { useLoadingStore } from '@/stores/useLoadingStore';
import { useValidationStore } from '@/stores/useValidationStore';
import { z } from 'zod';
import api from '@/api';

const stateSchema = z.object({
  personal: personalDataResource,
  terms: z.boolean(),
  comment: z.string().nullable(),
  authentication: z.object({
    action: z.enum(['guest', 'register', 'login']),
    access_token: z.string().nullable(),
    email: z.string().nullable(),
    password: z.string().nullable(),
    password_confirmation: z.string().nullable(),
  }),
});

export type State = z.infer<typeof stateSchema>;

export const useCustomerStore = defineStore('customer', {
  state: (): State => ({
    personal: {
      birth_date: persist('customer/personal/birth_date', null),
      email: persist('customer/personal/email', null),
      first_name: persist('customer/personal/first_name', null),
      gender: persist('customer/personal/gender', null),
      infix: persist('customer/personal/infix', null),
      last_name: persist('customer/personal/last_name', null),
      phone_number: persist('customer/personal/phone_number', null),
      address: {
        city: persist('customer/personal/city', null),
        country: persist('customer/personal/country', null),
        house_number: persist('customer/personal/house_number', null),
        house_number_addition: persist('customer/personal/house_number_addition', null),
        street: persist('customer/personal/street', null),
        zip_code: persist('customer/personal/zip_code', null),
      },
      opt_in: persist('customer/personal/opt_in', false),
      thermen_fans: persist('customer/personal/thermen_fans', false),
      send_invoice: false,
      invoice: {
        business_name: persist('customer/personal/invoice/business_name', null),
        email: persist('customer/personal/invoice/email', null),
        first_name: persist('customer/personal/invoice/first_name', null),
        infix: persist('customer/personal/invoice/infix', null),
        last_name: persist('customer/personal/invoice/last_name', null),
        phone_number: persist('customer/personal/invoice/phone_number', null),
        comment: persist('customer/personal/invoice/memo', null),
        opt_in: persist('customer/personal/invoice/opt_in', false),
        address: {
          city: persist('customer/personal/invoice/city', null),
          country: persist('customer/personal/invoice/country', null),
          house_number: persist('customer/personal/invoice/house_number', null),
          house_number_addition: persist('customer/personal/invoice/house_number_addition', null),
          street: persist('customer/personal/invoice/street', null),
          zip_code: persist('customer/personal/invoice/zip_code', null),
        }
      }
    },
    terms: false, // Do not persist, customer must check this explicit i.c.o. changes in terms.
    comment: persist('customer/comment', null),
    authentication: {
      action: persist('customer/action', 'login'),
      access_token: persist('customer/access_token', null),
      email: null,
      password: null,
      password_confirmation: null,
    },
  }),

  getters: {
    hasComment(state) {
      return !!state.comment;
    },

    hasBirthDate(state) {
      return !!state.personal.birth_date;
    },

    isAuthenticated(state) {
      return state.authentication.action === 'login' && !!state.authentication.access_token;
    },
  },

  actions: {
    logout() {
      this.authentication.access_token = null;
      api.client.delAccessToken();
    },

    resetPersonalDetails() {
      this.personal.birth_date = null;
      this.personal.email = null;
      this.personal.first_name = null;
      this.personal.gender = null;
      this.personal.infix = null;
      this.personal.last_name = null;
      this.personal.phone_number = null;
      this.personal.address.city = null;
      this.personal.address.country = null;
      this.personal.address.house_number = null;
      this.personal.address.house_number_addition = null;
      this.personal.address.street = null;
      this.personal.address.zip_code = null;
    },

    async loginWithAccessToken(access_token: string) {
      const loadingStore = useLoadingStore();
      return new Promise<boolean>(async (resolve, reject) => {
        const loaders = loadingStore.loading('login');

        this.authentication.access_token = access_token;
        api.client.setAccessToken(this.authentication.access_token);

        try {
          const response = await api.account.me();
          this.personal.birth_date = response.member.birth_date;
          this.personal.email = response.member.email;
          this.personal.first_name = response.member.first_name;
          this.personal.gender = response.member.gender;
          this.personal.infix = response.member.infix;
          this.personal.last_name = response.member.last_name;
          this.personal.phone_number = response.member.phone_number;
          this.personal.address.city = response.member.address?.city  || '';
          this.personal.address.country = response.member.address?.country || '';
          this.personal.address.house_number = response.member.address?.house_number || '';
          this.personal.address.house_number_addition = response.member.address?.house_number_addition || '';
          this.personal.address.street = response.member.address?.street || '';
          this.personal.address.zip_code = response.member.address?.zip_code || '';
          this.authentication.action = 'login';
          resolve(true);
        } catch (error) {
          console.log(error);
          this.resetPersonalDetails();
          this.authentication.access_token = null;
          this.authentication.action = 'guest';
          api.client.delAccessToken();
          resolve(false);
        }

        loadingStore.unloading(...loaders);
      });
    },

    async login() {
      const validationStore = useValidationStore();
      const loadingStore = useLoadingStore();

      return new Promise<boolean>(async (resolve, reject) => {
        const loaders = loadingStore.loading('login');

        try {
          const response = await api.account.login(this.authentication.email ?? '', this.authentication.password ?? '');
          this.personal.birth_date = response.member.birth_date;
          this.personal.email = response.member.email;
          this.personal.first_name = response.member.first_name;
          this.personal.gender = response.member.gender;
          this.personal.infix = response.member.infix;
          this.personal.last_name = response.member.last_name;
          this.personal.phone_number = response.member.phone_number;
          this.personal.address.city = response.member.address?.city || '';
          this.personal.address.country = response.member.address?.country || '';
          this.personal.address.house_number = response.member.address?.house_number || '';
          this.personal.address.house_number_addition = response.member.address?.house_number_addition || '';
          this.personal.address.street = response.member.address?.street || '';
          this.personal.address.zip_code = response.member.address?.zip_code || '';
          this.authentication.access_token = response.authorization.access_token;

          api.client.setAccessToken(this.authentication.access_token);

          //send the access_token to the comm script
          sendMessage('reservationflow', 'reservationflow.authenticated', {
            access_token: this.authentication.access_token
          });

          validationStore.clearErrors();

          this.authentication.password = null;
          resolve(true);
        } catch (error) {
          if (isAxiosError(error) && error.response?.data?.errors) {
            validationStore.setErrors(error.response.data.errors);
          }
          if (isAxiosError(error) && error.response?.status === 401) {
            validationStore.setErrors({ 'authentication': [error.response?.data.message] });
          }
          reject(error);
        }

        loadingStore.unloading(...loaders);
      });
    },

    async forgotPassword() {
      if (!this.authentication.email) return;

      const response = await api.account.forgotPassword(this.authentication.email);
      return response.status;
    },
  }
});
