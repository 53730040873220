<template>
  <Modal ref="modal">
    <LoadingOverlay v-if="loading" />

    <h1 class="text-2xl font-serif text-center">{{ $t('are_you_sure_you_want_to_reset_your_password') }}</h1>

    <p class="text-center mt-6">
      <span v-if="status">{{ status }}</span>
      <span v-else>{{ $t('an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password') }}</span>
    </p>

    <template #footer>
      <div class="flex gap-4 flex-wrap justify-center">
        <Button variant="light" @click="closeModal">
          {{ $t('close')}}
        </Button>
        <Button variant="primary" @click="send" v-if="!status">
          {{ $t('reset_password')}}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { useCustomerStore } from '@/stores/useCustomerStore';
  import Button from '@/components/Button.vue';
  import LoadingOverlay from '../LoadingOverlay.vue';
  import Modal from '@/components/Modal.vue';

  const customerStore = useCustomerStore();

  const loading = ref(false);
  const modal = ref<InstanceType<typeof Modal>>();
  const status = ref<string>();

  const openModal = () => {
    modal.value?.openModal();
  };

  const closeModal = () => {
    loading.value = false;
    status.value = undefined;
    modal.value?.closeModal();
  };

  const send = async () => {
    loading.value = true;
    try {
      status.value = await customerStore.forgotPassword();
    } catch(error) {
      console.log(error);
    }
    loading.value = false;
  }

  defineExpose({
    openModal,
    closeModal,
  });
</script>
