import type { Plugin } from 'vue';
import { useSessionStorage as useStorage } from '@vueuse/core';
import { useFlowStore, type State as FlowStoreState } from '@/stores/useFlowStore';
import { useCustomerStore, type State as CustomerStoreState } from '@/stores/useCustomerStore';
import { useValidationStore, type State as ValidationStoreState } from '@/stores/useValidationStore';
import { useLoadingStore, type State as LoadingStoreState } from '@/stores/useLoadingStore';

// https://cube-nl.sentry.io/issues/4927931166/events/146e98377e904ec0bb8c688244818192/?project=4506579637960704
// Safari > Settings > Geavanceerd > Blokkeer alle cookies

const persistedData = new Map<string, any>();

/**
 * Presist data in internal browser session storage.
 *
 * This return type T is actualy incorrect, useStorage returns a Ref<T>, but then
 * Zod will complain about it, that's why we return the value type of the ref it self.
 *
 * To use this persist function outsize a Pinia store it must be wrapped in a ref:
 * `ref(persist('name', '')).value = 'example';`
 *
 * see https://github.com/vueuse/vueuse/issues/1496
 */
export const persist = <T extends any>(id: string, defaultValue: T): T => {
  try {
    return (useStorage(id, defaultValue) as unknown) as T;
  } catch (error) {
    // When the user blocks all cookies, store the data just in memory.
    if (!persistedData.has(id)) {
      persistedData.set(id, defaultValue);
    }

    return persistedData.get(id) as T;
  }
};

export const stores: Plugin = {
  install: ({ config }) => {
    config.globalProperties.$flowstore = useFlowStore();
    config.globalProperties.$customerstore = useCustomerStore();
    config.globalProperties.$validationstore = useValidationStore();
    config.globalProperties.$loadingstore = useLoadingStore();
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $flowstore: FlowStoreState,
    $customerstore: CustomerStoreState,
    $validationstore: ValidationStoreState,
    $loadingstore: LoadingStoreState,
  }
}
