import type { MessageSchema } from '@/translations';

const messages: MessageSchema = {
  a_few_more_details: 'Einige weitere Details',
  accept_mailinglist_sentence: 'Ich melde mich für die E-Mails von Thermen {resort} mit Neuigkeiten und persönlichen Angeboten an',
  accept_retreats_mailinglist_sentence: 'Ich melde mich für die E-Mails von Retreats mit Neuigkeiten und persönlichen Angeboten an',
  accept_terms_sentence: 'Hiermit bestätige ich, den folgenden Bedingungen zuzustimmen: {terms_list}',
  accept_thermenfans_sentence: 'Ich melde mich für das {link} an und profitiere daher von exklusiven Vorteilen wie: Angeboten, personalisierten Rabattgutscheinen, netten Geschenken und exklusivem Zugang zu Veranstaltungen',
  add_one: 'Hinzufügen',
  all_choices: 'Alle Auswahlen',
  an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password: 'Eine E-Mail wird an Ihre E-Mail-Adresse gesendet, wenn Sie auf "Passwort zurücksetzen" klicken. In dieser E-Mail befindet sich ein Link, um ein neues Passwort anzufordern.',
  are_you_sure_you_want_to_reset_your_password: 'Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen möchten?',
  arrangement_choice_nr: 'Arrangement-Auswahl {n}',
  arrangement_choices: 'Arrangement @:choices',
  arrival_and_departure_time_explanation: 'Für einen entspannten Besuch bitten wir dich, zu den Zeiten deiner Reservierung anzukommen und abzureisen. So halten wir gemeinsam ein angenehmes Gleichgewicht bei der Anzahl der Gäste, die bei uns ein- und auschecken.',
  arrival: 'Ankunft',
  belgium: 'Belgien',
  business_name: 'Name der Firma',
  cancellation_policy: 'Stornierungsbedingungen',
  choices: 'Auswahl | Auswahlen',
  choose_arrangement: 'Machen Sie Ihr Arrangement wirklich zu Ihrem eigenen. Wählen Sie aus den folgenden Optionen Ihren Favoriten für Ihren Wellness-Tag aus.',
  choose_your_arrival_and_departure_day_in_the_calendar: 'Wählen Sie Ihren Ankunfts- und Abreisetag im Kalender',
  choose_your_type_of_entrance: 'Wählen Sie Ihren Eintrittstyp',
  city: 'Stadt',
  close: 'Schließen',
  comments_or_extra_information: 'Kommentare / zusätzliche Informationen',
  compare_termal_title: 'Eintritt Thermalbad und Eintritt Thermalbad + Sauna\n',
  compare_termal_comment: 'Da wir wissen, dass jeder Entspannung anders erlebt, haben wir zwei verschiedene Zonen in unserem Resort geschaffen. Buchst du einen Thermalbad-Eintritt oder einen Eintritt für das Thermalbad inkl. Saunabereich? Finde heraus, wo du am meisten genießen wirst:',
  compare_etbs_title: 'Thermalbad + Sauna Eintritt',
  compare_etbs_comment: 'Dieser Bereich ist ohne Kleidung zugänglich. Nur am Badekleidungstag trägst du Badekleidung.',
  compare_etbs_usp_1: 'Zugang zu allen Saunafazilitäten (mehr als 10 Saunen)',
  compare_etbs_usp_2: 'Zugang zu all unseren Thermalbädern',
  compare_etbs_usp_3: 'Zugang zu unserem Dampfbadhaus, der Parkserre, Ruheräumen mit Lavendelzimmer',
  compare_etbs_usp_4: 'Zugang zu den Liegewiesen und dem Bademantelpark',
  compare_etbs_usp_5: 'Zugang zum Restaurant Bubbels und zur Sauna Lodge',
  compare_etb_title: 'Thermalbad Eintritt',
  compare_etb_comment: 'Dieser Bereich ist nur bekleidet zugänglich. Badekleidung ist Pflicht.',
  compare_etb_usp_1: 'Zugang zu zwei Thermalbädern',
  compare_etb_usp_2: 'Zugang zu unserem Dampfbadhaus, der Parkserre, Ruheräumen mit Lavendelzimmer',
  compare_etb_usp_3: 'Zugang zu den Liegewiesen und dem Bademantelpark',
  compare_etb_usp_4: 'Zugang zum Restaurant Bubbels',
  country: 'Land',
  customer_page_into_sentence: 'Wir benötigen etwas mehr Informationen von Ihnen. Wirklich nur ein bisschen. Und wir behalten Ihre Daten natürlich für uns. Versprochen.',
  date_of_birth: 'Geburtsdatum',
  day: 'Tag',
  departure: 'Abfahrt',
  email_address: 'E-Mail-Adresse',
  estimated_time_of_arrival: 'Geschätzte Ankunftszeit',
  estimated_time_of_departure: 'Geschätzte Abfahrtszeit',
  evening: 'Abend',
  first_name: 'Vorname',
  for_who: 'Für wen?',
  forgot_your_password_no_problem: 'Passwort vergessen? Kein Problem.',
  germany: 'Deutschland',
  go_to_the_website: 'Zur Website gehen',
  hide_all_single_rooms: 'Alle Einzelzimmer ausblenden',
  hotel_room_choice: 'Hotelzimmerauswahl',
  hotel_room_type: 'Einzelzimmer | Doppelzimmer',
  hotel_rooms_page_into_sentence: 'Kommst du, um bei uns zu übernachten?',
  hotel_rooms: 'Hotelzimmer',
  house_number_addition: 'Zusatz',
  house_number: 'Hausnummer',
  house_rules: 'Hausregeln',
  how_long: 'Wie lange?',
  how_would_you_like_to_book_your_relaxing_moment: 'Wie möchten Sie Ihren Entspannungsmoment buchen?',
  i_have_a_my_thermen_account: 'Ich habe ein Thermen Resorts-Konto',
  i_want_to_create_a_my_thermen_account_tooltip: 'Schnellere Reservierungen und nie wieder deine Daten eingeben müssen. Ein Thermen Resorts Konto bietet dir mehr. Mehr Bequemlichkeit und mehr Vorteile. Beachte! Dein Konto wird aktiviert, indem du auf den Link in der Verifizierungs-E-Mail klickst.',
  i_want_to_create_a_my_thermen_account: 'Ich möchte ein Thermen Resorts-Konto erstellen',
  i_want_to_make_a_reservation_without_an_account: 'Ich möchte eine Reservierung ohne Konto vornehmen',
  i_would_like_to_create_an_account: 'Ich möchte ein Konto erstellen',
  invoice_comment_placeholder: 'Kommentare/Details (z. B. Kaufnummer)',
  invoice_information: 'Rechnungsinformationen des Arbeitgebers',
  last_name: 'Nachname',
  login: 'Einloggen',
  logout: 'Abmelden',
  lunch_and_diner_page_into_sentence: 'Ihr Wellness-Tag noch vollständiger und noch schöner? Entdecken Sie unsere Aufgüsse, Rituale, Meditationen und andere angenehme Entspannungsmomente... Unser Tipp? Wählen Sie, was Sie glücklich macht!',
  lunch_and_diner: 'Mittagessen und Abendessen',
  make_it_your_day: 'Machen Sie es zu Ihrem Tag!',
  mobile_phone_number: 'Handynummer',
  month: 'Monat',
  more_info: 'Mehr Info',
  more_information: 'Weitere Informationen',
  morning: 'Morgen',
  mr: 'Herr',
  ms: 'Frau',
  name_infix: 'Zwischenname',
  next: 'Weiter',
  notice_chosen_date_or_time_is_unavailable: 'Das gewählte Datum und/oder Uhrzeit ist nicht verfügbar!',
  notice_this_hotel_room_is_unavailable: 'Dieses Zimmer ist an dem gewählten Tag nicht mehr verfügbar!',
  number_of_persons: '{n} Person | {n} Personen',
  or_stay_with_us_for_a_good_night_sleep: 'Oder bleiben Sie bei uns für eine gute Nachtruhe?',
  other: 'Andere',
  password_confirmation: 'Passwort wiederholen',
  password: 'Passwort',
  pay: 'Bezahlen',
  per_night: 'pro Nacht',
  per_person_including_breakfast_starting_from: 'p.p. inklusive Frühstück ab',
  per_person_short: 'p.P.',
  persons_count: 'Anzahl der Personen',
  persons: 'Person | Personen',
  previous: 'Zurück',
  prices_are_in_euro: 'Preise in € @:per_person_short',
  privacy_policy: 'Datenschutzerklärung',
  receive_a_present_on_your_birthday: 'Ein Geschenk zum Geburtstag erhalten?',
  reload: 'Neu laden',
  request_your_new_password_here: 'Fordere hier dein neues Passwort an.',
  reset_password: 'Passwort zurücksetzen',
  reset: 'Zurücksetzen',
  rituals_extras_page_into_sentence: 'Möchtest du deinen Wellness-Tag noch vollständiger und angenehmer gestalten? Entdecke unsere Aufgüsse, Rituale, Meditationen und andere angenehme Entspannungsmomente... Unser Tipp? Wähle vor allem das, was dich glücklich macht!',
  rituals_extras: 'Extras für deinen Saunatag',
  select_all: 'Alles auswählen',
  select_for_all: 'Für alle auswählen',
  select: 'Auswählen',
  selected: 'Ausgewählt',
  send_invoice: 'Mein Arbeitgeber bezahlt die Rechnung.',
  share_a_room_together_select_one_of_the_available_room_options_below: 'Teile ein Zimmer zusammen, wähle eine der verfügbaren Zimmeroptionen unten aus.',
  show_all_single_rooms: 'Alle Einzelzimmer anzeigen',
  something_went_wrong_while_retrieving_your_data: 'Beim Abrufen deiner Daten ist ein Fehler aufgetreten.',
  street: 'Straße',
  swimwear_day_tooltip_sentence: 'Die blau markierten Tage im Kalender sind Badekleidungstage. An diesen Tagen ist das Tragen von Badekleidung obligatorisch.',
  swimwear_day: 'Badekleidungstag',
  terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
  terms_item_1: 'Meine Ankunfts- und Abreisezeit (damit eventuelle Behandlungen innerhalb einer halben Stunde nach der angegebenen Ankunftszeit und vor der angegebenen Abreisezeit stattfinden können)',
  terms_item_2: 'Die {house_rules} gelesen zu haben',
  terms_item_3: 'Die {terms_and_conditions}, {cancellation_policy} und {privacy_policy}',
  the_difference: 'Der Unterschied',
  the_netherlands: 'Niederlande',
  there_are_no_selectable_products_available_at_the_selected_time: 'Zum ausgewählten Zeitpunkt sind keine auswählbaren Produkte verfügbar.',
  thermenfans_program: 'Thermenfans-Programm',
  total: 'Gesamt',
  treatment_page_into_sentence: 'Dein Tag bei uns kann noch vollständiger sein. Eine Massage? Eine Behandlung...? Unser Tipp: Wähle das, was dich glücklich macht, das ist immer die beste Wahl.',
  unavailable: 'Nicht verfügbar',
  view_reservation: 'Reservierung anzeigen',
  we_put_together_the_available_options_for_you: 'Wir stellen die verfügbaren Optionen für Sie zusammen...',
  what_is_the_difference: 'Was ist der Unterschied?',
  what_would_you_like_to_book: 'Was möchtest du buchen?',
  when_are_you_coming_to_relax: 'Wann kommst du zum Entspannen?',
  when_resetting_previously_entered_data_will_be_lost: 'Beim Zurücksetzen gehen zuvor eingegebene Daten verloren.',
  which_type_of_treatment_suits_you: 'Welche Behandlung passt zu dir:',
  year: 'Jahr',
  you_have_number_of_choices: 'Sie haben <strong>{n}</strong> Wahl|Sie haben <strong>{n}</strong> Möglichkeiten',
  you_have: 'Du hast',
  your_experience: 'Dies erwartet dich:',
  your_first_step_to_great_enjoyment: 'Dein erster Schritt zum großen Genuss!',
  zip_code: 'Postleitzahl',
  steps: {
    choices: 'Deine Auswahl',
    complete: 'Abschluss',
    customer_details: 'Kundendaten',
    reservation: 'Reservierung',
  },
};

export default messages;
