import config from '@/config';
import {createRouter, createWebHistory} from 'vue-router';
import Reservation from '@/views/Reservation.vue';
import ChoicesView from '@/views/Choices.vue';
import CustomerView from '@/views/Customer.vue';
import CompleteView from '@/views/Afronden.vue';

const routesPaths = {
  'nl-NL': {
    choices: 'jouw-keuzes',
    customer: 'gegevens',
    lunch_and_diner: 'lunch-and-diner',
    hotel_rooms: 'hotel-rooms',
    treatments: 'behandelingen',
    rituals: 'rituelen',
  },
  'de-DE': {
    choices: 'deine-entscheidungen',
    customer: 'personendaten',
    lunch_and_diner: 'mittagessen-und-abendessen',
    hotel_rooms: 'hotelzimmer',
    treatments: 'behandlungen',
    rituals: 'rituale',
  },
  'en-US': {
    choices: 'your-choices',
    customer: 'customer-details',
    lunch_and_diner: 'lunch-and-diner',
    hotel_rooms: 'hotel-rooms',
    treatments: 'treatments',
    rituals: 'rituals',
  },
  'fr-FR': {
    choices: 'vos-choix',
    customer: 'details-du-client',
    lunch_and_diner: 'dejeuner-et-diner',
    hotel_rooms: 'chambres-dhotel',
    treatments: 'traitements',
    rituals: 'rituels',
  },
} as const;

const keepQueryParameters = [
  "ga_origin",
  "_gl"
] as const;

const localeRoutePath = routesPaths[config.locale as keyof typeof routesPaths];

export const routes = [
  {
    path: '/',
    name: 'reservation',
    meta: { stepable: true, validator: 'reservation' },
    component: Reservation,
  },
  {
    path: `/${localeRoutePath.choices}`,
    name: 'choices',
    redirect: { name: 'reservation' },
    component: ChoicesView,
    children: [
      {
        path: localeRoutePath.lunch_and_diner,
        name: 'lunch_and_diner',
        meta: { stepable: true, validator: 'upsell_horeca_and_hotel' },
        component: () => import('@/views/LunchAndDiner.vue'),
      },
      {
        path: localeRoutePath.treatments,
        name: 'treatments',
        meta: { stepable: true },
        component: () => import('@/views/Treatment.vue'),
      },
      {
        path: localeRoutePath.rituals,
        name: 'rituals',
        meta: { stepable: true },
        component: () => import('@/views/Rituals.vue'),
      },
    ],
  },
  {
    path: `/${localeRoutePath.customer}`,
    name: 'customer',
    meta: { stepable: true },
    component: CustomerView,
  },
  {
    path: '/afronden',
    name: 'complete',
    meta: { stepable: true },
    component: CompleteView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    redirect: { name: 'reservation' },
  },
];

/**
 * Ugly way to remove unused choice steps dynamically
 */
export const removeOptionalRoutes = (routeNames: string[]) => {
  const removedOptionalRoutes: string[] = [];

  routeNames.forEach(routeName => {
    if (router.hasRoute(routeName)) {
      router.removeRoute(routeName);
      removedOptionalRoutes.push(routeName);
    }
  });

  if (removedOptionalRoutes.length > 0) console.warn('Removed optional routes:', removedOptionalRoutes);

  const choicesRoute = routes.find(route => route.name === 'choices');
  if (choicesRoute) {
    choicesRoute.children = choicesRoute.children?.filter(route => !routeNames.includes(route.name));
  }
};

const router = createRouter({
  history: createWebHistory(config.baseUrl),
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, /*behavior: 'smooth'*/ };
    }
  },
});

router.beforeEach((to, from, next) => {
  const queryParams = from.query;

  if(!Object.keys(to.query).length && queryParams !== null && Object.keys(queryParams).length) {
    const keep = Object.fromEntries(keepQueryParameters.map(col => [col, queryParams[col]]).filter(([key, value]) => !!value));

    if(Object.keys(keep).length) {
      next({name: to.name, query: keep});
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;
