import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { persist } from '@/stores';
import { routes } from '@/router';
import { useRoute, useRouter } from 'vue-router';

export type AvailableStep = {
  name: string
  parent?: string
  percentage: number
  validator?: string
}

export const useNavigationStore = defineStore('navigation', () => {
  const router = useRouter();
  const route = useRoute();

  // Remember the path the user is currently at
  const rememberedPath = ref(persist('app/path', '/'));
  watch(() => route.path, currentPath => rememberedPath.value = currentPath);

  // Used to recompute steps, manually removing the child routes will not trigger the computed value.
  const recomputeId = ref(0)

  const steps = computed(() => {
    recomputeId.value; // leave this here, so we can trigger a recompute.

    const availableSteps: AvailableStep[] = [];

    routes.forEach(route => {
      if (route.meta?.stepable) {
        availableSteps.push({
          name: route.name,
          parent: undefined,
          percentage: 100,
          validator: route.meta?.validator,
        });
      }

      if (route.children) {
        const childSteps = route.children.filter(child => child.meta?.stepable && router.hasRoute(child.name));
        const stepSize = 100 / childSteps.length;

        childSteps.forEach((child, index) => {
          availableSteps.push({
            name: child.name,
            parent: route.name,
            percentage: stepSize * (index + 1),
            validator: child.meta?.validator,
          });
        });
      }
    });

    return availableSteps;
  });

  const currentStepIndex = () => steps.value.findIndex(step => step.name === route?.name?.toString());

  const recomputeAvailableSteps = () => {
    recomputeId.value += 1;
  };

  const previous = computed(() => {
    const index = currentStepIndex();
    if (index > 0) return steps.value[index - 1];
  });

  const next = computed(() => {
    const index = currentStepIndex();
    if (index < steps.value.length) return steps.value[index + 1];
  });

  const current = computed(() => {
    const index = currentStepIndex();
    if (steps.value[index]) {
      return steps.value[index].parent ? steps.value[index].parent : steps.value[index].name;
    }
  });

  const currentRouteName = computed(() => {
    const index = currentStepIndex();
    if (steps.value[index]) return steps.value[index].name;
  });

  const currentRouteValidator = computed(() => {
    const index = currentStepIndex();
    if (steps.value[index]) return steps.value[index].validator;
  });

  const upcomingSteps = computed(() => {
    const index = currentStepIndex();
    return steps.value.slice(index + 1);
  });

  const passedSteps = computed(() => {
    const index = currentStepIndex();
    return steps.value.slice(0, index);
  });

  const gotoPreviousStep = async () => {
    const index = currentStepIndex();
    if (index > 0) {
      await router.push({ name: steps.value[index - 1].name });
    }
  };

  const gotoNextStep =  async () => {
    const index = currentStepIndex();
    if (index < steps.value.length) {
      await router.push({ name: steps.value[index + 1].name });
    }
  };

  const gotoStep = async (name: string) => {
    await router.push({ name });
  };

  const gotoFirstStep = async () => {
    await router.push({ name: steps.value[0].name });
  };

  const gotoRememberedPath = async () => {
    if (route.path === rememberedPath.value) return;
    await router.push(rememberedPath.value);
  };

  const resetRememberedPath = () => {
    rememberedPath.value = '/';
  };

  const progress = computed(() => {
    const currentRoute = route?.name?.toString();
    const currentRouteIndex = currentStepIndex();
    const stepProgressRegister = new Map<string, number>();

    steps.value.forEach((step, stepIndex) => {
      // Include the parent stap and set it to a default of 0.
      if (step.parent && !stepProgressRegister.has(step.parent)) {
        stepProgressRegister.set(step.parent, 0);
      }

      let percentage = 0;

      if (currentRoute === step.name && step.parent) {
        percentage = step.percentage;
      } else if (currentRoute === step.name || stepIndex < currentRouteIndex) {
        percentage = 100;
      }

      if (!step.parent) stepProgressRegister.set(step.name, percentage);

      if (step.parent && percentage > 0) {
        stepProgressRegister.set(step.parent, percentage);
      }
    });

    return Object.fromEntries(stepProgressRegister.entries());
  });

  const totalProgress = computed(() => {
    const percentageMax = Object.keys(progress.value).length * 100;
    const percentageSumOfSteps = Object.values(progress.value).reduce((sum, value) => sum + value, 0);
    return (percentageSumOfSteps / percentageMax) * 100;
  });

  return {
    steps,
    previous,
    next,
    current,
    currentRouteName,
    currentRouteValidator,
    upcomingSteps,
    passedSteps,
    progress,
    totalProgress,
    recomputeAvailableSteps,
    currentStepIndex,
    gotoPreviousStep,
    gotoNextStep,
    gotoStep,
    gotoFirstStep,
    gotoRememberedPath,
    resetRememberedPath,
  }
});

// if (import.meta.hot) {
//   import.meta.hot.accept(acceptHMRUpdate(useNavigationStore, import.meta.hot));
// }
