<template>
  <Modal ref="modal">
    <template #header>
      <div class="grid grid-cols-2">
        <div v-for="checkoutType in checkoutTypes" :key="checkoutType.type" class="bg-qwr-dark-50 aspect-video">
          <img v-if="checkoutType.image" class="h-full w-full object-cover" :src="checkoutType.image" loading="lazy" />
        </div>
      </div>
    </template>

    <h1 class="text-2xl font-serif">{{ $t('what_is_the_difference') }}</h1>

    <div v-if="showThermalBathText()">
      <h2 class="font-bold mt-4">{{ $t('compare_termal_title') }}</h2>
      <p class="mb-6">{{ $t('compare_termal_comment') }}</p>
      <div class="mb-2">
        <h2 class="inline-block font-bold mb-0 mr-2">{{ $t('compare_etbs_title') }}</h2>
        <p class="inline italic text-sm">{{ $t('compare_etbs_comment') }}</p>
      </div>
      <ul class="list-disc list-inside mb-6">
        <li>{{ $t('compare_etbs_usp_1') }}</li>
        <li>{{ $t('compare_etbs_usp_2') }}</li>
        <li>{{ $t('compare_etbs_usp_3') }}</li>
        <li>{{ $t('compare_etbs_usp_4') }}</li>
        <li>{{ $t('compare_etbs_usp_5') }}</li>
      </ul>
      <div class="mb-2">
        <h2 class="font-bold inline-block mb-0 mr-2">{{ $t('compare_etb_title') }}</h2>
        <p class="inline italic text-sm">{{ $t('compare_etb_comment') }}</p>
      </div>
      <ul class="list-disc list-inside">
        <li>{{ $t('compare_etb_usp_1') }}</li>
        <li>{{ $t('compare_etb_usp_2') }}</li>
        <li>{{ $t('compare_etb_usp_3') }}</li>
        <li>{{ $t('compare_etb_usp_4') }}</li>
      </ul>
    </div>

    <div v-else v-for="checkoutType in checkoutTypes" :key="checkoutType.type">
      <h2 class="font-bold my-2">{{ checkoutType.name }}</h2>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import Modal from '@/components/Modal.vue';
  import type { CheckoutTypeResource } from '@/api/resources';

  export interface Props {
    checkoutTypes: CheckoutTypeResource[]
  }

  const props = defineProps<Props>();

  const modal = ref<InstanceType<typeof Modal>>();

  const openModal = () => {
    modal.value?.openModal();
  };

  const closeModal = () => {
    modal.value?.closeModal();
  };

  const showThermalBathText = () => {
    const requiredTypes = ['entree-thermaalbad', 'entree-thermaalbad-plus-sauna'];

    return requiredTypes.every(type =>
      props.checkoutTypes.some(checkoutType => checkoutType.type === type)
    );
  }

  defineExpose({
    openModal,
    closeModal,
    showThermalBathText
  });
</script>
