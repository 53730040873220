<template>
  <div v-if="products.length > 0">
    <h3  class="text-2xl font-serif">{{ $t('arrangement_choice_nr', choice) }}</h3>

    <Transition name="fade">
      <NoteBlock v-if="errors" variant="error" class="my-6 error-scroll-anchor">
        {{ errors[0] }}
      </NoteBlock>
    </Transition>

    <Slider header :settings="{ gutter: 32, items: 2, nav: false }" :key="sliderUpdateKey">
      <div v-for="product in products" :key="product.id" class="pb-4">
        <UpsellProductCard :product="product" :group="group" />
      </div>
    </Slider>
  </div>
</template>

<script lang="ts" setup>
  import type { UpsellProductResource } from '@/api/resources';
  import UpsellProductCard from '@/components/UpsellProductCard.vue';
  import Slider from '@/components/Slider.vue';
  import NoteBlock from '@/components/NoteBlock.vue';
  import { ref, watch } from 'vue';

  export interface Props {
    group: string
    choice: number
    errors?: string[]
    products: UpsellProductResource[]
  }

  const props = defineProps<Props>();

  const sliderUpdateKey = ref(props.products.map(x => x.id).join(''));

  watch(() => props.products, (newValue) => {
    const ids = newValue.map(x => x.id).join('');

    if (sliderUpdateKey.value !== ids) {
      sliderUpdateKey.value = ids;
    }
  });
</script>
