import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "m8.787 10.153 1.471 2.014L11.367 11 9.823 9.453c.709-.95.527-2.517-.527-3.8-1.236-1.517-3.218-2.1-4.418-1.283s-1.164 2.717.073 4.233c1.073 1.3 2.655 1.9 3.836 1.55M13.696 13.596l-1.2 1.717 2.413 3.52c.418.6 1.309.75 1.945.317l.109-.083c.618-.433.727-1.25.218-1.8l-3.486-3.67z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#dcdcdc",
      d: "M19.795 5.796a.416.416 0 0 0-.618.017L16.522 8.93a.3.3 0 0 1-.364.05c-.109-.083-.145-.217-.055-.333l2.491-3.217a.353.353 0 0 0-.018-.467.416.416 0 0 0-.618.017l-2.655 3.117a.3.3 0 0 1-.364.05c-.109-.083-.145-.217-.055-.333.564-.717 2.509-3.233 2.509-3.233.145-.183.073-.45-.164-.55a.45.45 0 0 0-.491.117l-2.8 3.3-.364.433c-.364.45-.527.983-.455 1.45l.164.967-6.964 7.4c-.509.55-.4 1.367.218 1.8l.109.083c.618.433 1.509.283 1.945-.317l5.745-8.217 1.073-.133c.509-.067 1.018-.367 1.4-.817l.364-.433 2.636-3.4a.353.353 0 0 0-.018-.467z"
    }, null, -1)
  ])))
}
export default { render: render }