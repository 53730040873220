import { checkoutResource, type ReceiptResource, type ResourceData } from '@/api/resources';
import type { CreateCartRequest } from '@/api/requests';
import {put} from "@/api/client";

export async function updateReceipt(guest_id: string, item_id: string, data: ReceiptResource): Promise<ReceiptResource> {
  // const signal = cancelPendingRequest('checkout.updateCheckout');
  const responseData = (await put<ResourceData<ReceiptResource>>(`remove/${ guest_id }/${item_id}`, data )).data;
  const result = checkoutResource.safeParse(responseData);
  return result.success ? responseData : Promise.reject(result.error);
}
