import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"current\" transform=\"translate(2 4)\"><path d=\"M16 12.706c0 .162-.112.294-.25.294H15V3h1zM16 2h-1v-.5c0-.169.174-.423.397-.486a.4.4 0 0 1 .079-.012L15.493 1h.007c.299 0 .5.2.5.5zm1 0v-.667c0-.755-.553-1.324-1.288-1.33L15.7 0H5.3C4.583 0 4 .508 4 1.133V15c0 .551.462 1 1 1h9.002c.537 0 1.01-.449 1.01-1L15 14.002h1.014a.99.99 0 0 0 .975-1L17 2.667zM5.5 13h8a.5.5 0 1 1 0 1h-8a.5.5 0 1 1 0-1\"></path><path d=\"M16 2h2v1h-2zM2 2h2v1H2z\"></path><circle cx=\"18.5\" cy=\"2.5\" r=\"1.5\"></circle><circle cx=\"1.5\" cy=\"2.5\" r=\"1.5\"></circle></g>", 1)
  ])))
}
export default { render: render }