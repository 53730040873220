<template>
  <Card>
    <button type="button" @click="toggle" class="flex w-full gap-4 min-h-14 text-left px-6 py-4">
      <i v-if="fonticon" :class="`icon-${fonticon} flex-none text-qwr-dark`" />
      <span class="flex-1 block">{{ title }}</span>
      <ChevronIcon class="mt-1 h-3 flex-none transition-transform" :class="{'rotate-90': !isOpen, '-rotate-90': isOpen}" />
    </button>
    <div :class="{
      'overflow-hidden transition-accordion': true,
      'max-h-0 opacity-0 duration-200': !isOpen,
      'max-h-[200rem] opacity-100 duration-500': isOpen
    }">
      <div class="px-6 pb-6">
        <slot />
      </div>
    </div>
  </Card>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import Card from './Card.vue';
  import ChevronIcon from 'thermen-theme/icons/chevron.svg?component';

  export interface Props {
    title: string
    opened?: boolean
    fonticon?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    opened: false,
  });

  const emit = defineEmits([
    'open',
    'close',
  ]);

  const isOpen = ref(props.opened);

  const toggle = () => {
    isOpen.value = !isOpen.value;
    emit(isOpen.value ? 'open' : 'close');
  };
</script>
