import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "#829838" }, [
      _createElementVNode("path", { d: "M4.993 9v7c0 1.7 1.51 3 3.486 3h7.027c1.976 0 3.487-1.3 3.487-3V9zM2 20h20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2" }),
      _createElementVNode("path", { d: "M19 9c-1.5 0-2.4 7-.5 7s3.5-1.6 3.5-3.5S20.5 9 19 9m-.5 5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5M10 6.5a1 1 0 1 1-2 0c0-1.462.704-2.614 2.021-3.332.81-.442 1.313-.957 1.553-1.545a1 1 0 0 1 1.852.754C13 3.425 12.17 4.274 10.98 4.923c-.684.373-.98.857-.98 1.577m4.952.306a1 1 0 0 1-1.904-.612c.267-.831 1.029-1.508 2.242-2.137.755-.392 1.135-.814 1.232-1.267a1 1 0 1 1 1.956.42c-.237 1.103-1.023 1.977-2.268 2.622-.787.408-1.192.768-1.258.974" })
    ], -1)
  ])))
}
export default { render: render }